import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import { ModelsName_NodesT, NODES_THREEKIT } from "../../../utils/constants/nodesNamesThreekit";
import { getTHREE } from "../../../utils/three/general/getFunctionsTHREE";
import { getEvalNode, getInstanceIdAssetFromNullModel } from "../../../utils/threekit/general/getFunctions";
import { getSizeModelRelativeTransform } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { checkModelBoxScale } from "../cabinetsWall/size";

const getNodeBoxForAppliances = (modelName: ModelsName_NodesT): ISceneResult => {
  const instanceId = getInstanceIdAssetFromNullModel({
    name: modelName,
  });
  
  const modelBoxEvalNodeDishwasher = getEvalNode({
    from: instanceId,
    name: "Dishwasher_mdl",
  });

  if (modelBoxEvalNodeDishwasher !== undefined)
    return modelBoxEvalNodeDishwasher;

  const modelBoxEvalNodeOven = getEvalNode({
    from: instanceId,
    name: "oven_*",
  });

  if (modelBoxEvalNodeOven !== undefined)
    return modelBoxEvalNodeOven;

  const modelBoxEvalNodeRefrigerator = getEvalNode({
    from: instanceId,
    name: "Refrigerator_*",
  });

  if (modelBoxEvalNodeRefrigerator !== undefined)
    return modelBoxEvalNodeRefrigerator;

  const modelBoxEvalNodeAppliance = getEvalNode({
    from: instanceId,
    name: "Appliance_*",
  });

  return modelBoxEvalNodeAppliance;
}

/**
 * Функція шукає та повертає EvalNode коробки для настінних шкафів.
 * Цей EvalNode показує реальні розміри каркасу шкафа без дверок, молдінгів, декоративних панелей і т.д.
 *
 * @param {ModelsName_NodesT} modelName Null name моделі, для якої шукаємо розміри.
 * @returns {ISceneResult} EvalNode.
 */
const getNodeForCabinetsBaseFrameOrAppliances = (modelName: ModelsName_NodesT): ISceneResult => {
  const instanceId = getInstanceIdAssetFromNullModel({
    name: modelName,
  });

  if (modelName.includes(NODES_THREEKIT.MODEL_APPLIANCES)) {
    return getNodeBoxForAppliances(modelName);
  }

  const modelBoxEvalNode = getEvalNode({
    from: instanceId,
    name: "cabinet_**_Base_Cabinet_*",
  });

  const modelBoxEvalNodeCornerEmpty = getEvalNode({
    from: instanceId,
    name: "Corner_empty_grp",
  });

  const modelBoxEvalNodeCornerLazySuzan = getEvalNode({
    from: instanceId,
    name: "handle_**_Suzan_Corner_Base_cabinet_*",
  });
 
  if (modelBoxEvalNodeCornerLazySuzan !== undefined)
    return modelBoxEvalNodeCornerLazySuzan;

  if (modelBoxEvalNodeCornerEmpty !== undefined)
    return modelBoxEvalNodeCornerEmpty;

  return modelBoxEvalNode;
}

/**
 * Функція для визначення розмірів моделі по її каркасу.
 * без урахування молдингів та інших навісних елементів тумби.
 *
 * @param {ModelCabinetWallT} modelName Null name моделі, для якої шукаємо розміри.
 * @returns {ICoordinates} Pозмір об'єкта.
 */
export const getSizeModelBoxFromAssetCabinetBase = (modelName: ModelsName_NodesT): ICoordinates => {
  const THREE = getTHREE();
  const modelBoxEvalNode = getNodeForCabinetsBaseFrameOrAppliances(modelName);
  try {
    //@ts-ignore
    const boundingBox = modelBoxEvalNode.getBoundingBox({
      //@ts-ignore
      relativeTransform: modelBoxEvalNode.worldTransform
    });
    const sizeModelBoxFromScale = boundingBox.getSize(new THREE.Vector3());
    const sizeModelBoxOriginal = checkModelBoxScale(sizeModelBoxFromScale, modelBoxEvalNode);
    return sizeModelBoxOriginal;
  } catch (error) {
    console.error(error);
    return getSizeModelRelativeTransform(modelName);
  }
}