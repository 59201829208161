import { MoveDirectionOnWallT } from "../../../../functionsConfigurator/cabinets/cabinetsBase/moving/moveAllCabinetsBaseOnWall";
import { ArrWallRangesT, checkCornerIntervalEmpty } from "../../../../functionsConfigurator/intervals/getIntervalsOnWallForCabinetsWall";
import { CORNER_EMPTY_WALL_SIZE_X, CORNER_EMPTY_WALL_SIZE_Z } from "../../../../functionsConfigurator/cabinets/constatns";
import { ModelsName_NodesT, NAME_WALL_CABINET_CORNER_STUB, NAME_WALL_CABINET_CORNER_STUB_36, WallItemT } from "../../../constants/nodesNamesThreekit";
import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import { ObjWallsDistanceT } from "../toolsDragCabinetsBase/generalFunc";
import { getItemNumberFromMetadataNullName } from "../../../../functionsConfigurator/cabinets/metadata";
import { getItemNodeFromNullModel } from "../../general/getFunctions";

/**
* Повертає відстань, на яку потрібно зсунути всі моделі на стіні, щоб вмістився кутовий елемент.
*
@param {MoveDirectionOnWallT} moveDirection - Напрям, в якому потрібно зсунути моделі на стіні.
@param {ArrWallRangesT} intervalsForWall - Массив інтервалів для стіни
@returns {number} Відстань, на яку потрібно зсунути всі моделі на стіні, щоб вмістився кутовий елемент.
*/
export const getMoveDistanceModelsWall = (
  moveDirection: MoveDirectionOnWallT,
  intervalsForWall: ArrWallRangesT
): number => {

  let moveDistance: number = 0;
  if (moveDirection === "right") {
    const interval = intervalsForWall[0];
    moveDistance = interval["empty"]
      ? CORNER_EMPTY_WALL_SIZE_X - interval["range"][1]
      : CORNER_EMPTY_WALL_SIZE_X;
  } else {
    const interval = intervalsForWall[intervalsForWall.length - 1];
    moveDistance = interval["empty"]
      ? CORNER_EMPTY_WALL_SIZE_Z - (interval["range"][1] - interval["range"][0])
      : CORNER_EMPTY_WALL_SIZE_Z;
  }
  return moveDistance;
};

/**
 * Функція перевіряє вільний чи є заповнений інтервал на стіні з заданої сторони на задану відстань.
 * Якщо на заданій відстані є зайнятий інтервал, то
 * перевіряє чи не є зайнятий інтервал, інтервалом зі старою позицією моделі, яку ми в даний момент переміщуємо.
 *
 * @param {ArrWallRangesT} intervalsInfoOnWall Массив всіх проміжків(заповнених та пустих) на стіні.
 * @param {Number} cornerOffset Відстань, на яку перевіряється наявність заповнених проміжків в масиві інтервалів intervalsInfoOnWall.
 * @param {"left" | "right"} side Визначає справа чи зліва шукаємо пустий проміжок.
 * @param {ModelsName_NodesT} modelNullName Name для Null моделі, яку переміщаемо в даний час.
 * @returns {boolean} Визначає потрібно чи ні ставити кутову модель-заглушку у кут.
 */
export const isFilledIntervalForWall = (
  intervalsInfoOnWall: ArrWallRangesT,
  cornerOffset: number,
  side: "left" | "right",
  modelNullName: ModelsName_NodesT
): boolean => {
  let result: boolean = false;
  const isCornerIntervalEmpty = checkCornerIntervalEmpty(
    intervalsInfoOnWall,
    cornerOffset,
    side
  );
  if (!isCornerIntervalEmpty) {
    result = true;

    const cloneIntervalsInfoOnWall = intervalsInfoOnWall.slice(0);
    if (side === "right") cloneIntervalsInfoOnWall.reverse();
    const firstFilledRange = cloneIntervalsInfoOnWall.find(
      (objInterval) => !objInterval["empty"]
    );
    if (firstFilledRange && firstFilledRange["name"] === modelNullName)
      result = false;
  }
  return result;
};

/**
* Функція для перевірки настінної моделі на кутову модель.
@param {ModelsName_NodesT} modelNullName - Item моделі з Threekit.
@returns {Boolean} true/false - є модель кутовою чи ні.
*/
export const checkIfWallCornerCabinet = (modelNullName: ModelsName_NodesT): boolean => {
  if (modelNullName === undefined || modelNullName.length === 0) return false;
  // const idModel = getItemNumberFromMetadataNullName(modelNullName);
  const itemNode = getItemNodeFromNullModel({
    name: modelNullName
  })
  if (itemNode === undefined) return false;
  return (
    // idModel === "1022" ||
    // idModel === "1023" ||
    // itemNode["name"].includes(NAME_WALL_CABINET_CORNER_STUB) ||
    // itemNode["name"].includes(NAME_WALL_CABINET_CORNER_STUB_36)

    itemNode["name"].includes("Corner") ||
    itemNode["name"].includes("1022") ||
    itemNode["name"].includes("1023")
  );
};

/**
 * Повертає відфільтрований об'єкт objDistance дистанцій, в якому залишаються тільки дистанції для стін в куті.
 * Якщо кількість елементів в об'єкті дорівнює 2, це значить що модель наблизилась в зону кута.
 * Дистанція до кута рахується з урахуванням розміру моделі.
 * Ця функція рахує дистанції для настінних шкафів
 *
 * @param {ObjWallsDistanceT} objDistance Об'єкт дистанцій до всіх стін кімнати.
 * @param {ICoordinates} modelSize Розмір моделі.
 * @return {ObjWallsDistanceT} cornerDistance; Відфільтрований об'єкт objDistance, в якому є дистанції для стін в кут яких наближається модель.
 */
export const checkApproachToCornerCabinetsWall = (
  objDistance: ObjWallsDistanceT,
  modelSize: ICoordinates
) => {
  const modelWidth = modelSize["x"];
  const modelDepth = modelSize["z"];

  // об'єкт, який буде містити відстані моделі до стін в кутах
  const cornerDistance: ObjWallsDistanceT = {};

  // масив імен стін
  const arrWallNameFromObjDistance = Object.keys(objDistance) as Array<
    keyof typeof objDistance
  >;

  arrWallNameFromObjDistance.forEach((wallName: WallItemT) => {
    const distanceFront = objDistance[wallName]["distanceFront"];
    const distancePositiveX = objDistance[wallName]["distanceLeftX"];
    const distanceNegativeX = objDistance[wallName]["distanceRightX"];
    const wallWidth = objDistance[wallName]["width"];

    // todo тут треба напевно враховувати прижимну планку
    // Перевіряємо, чи модель перебуває в зоні кута, обчислюємо відстань моделі до стін кута
    if (
      // Math.abs(distanceFront) <= modelDepth * 2 &&
      // Math.abs(distanceFront) <= (modelWidth / 2) + modelDepth + (modelDepth / 2) &&
      Math.abs(distanceFront) <= (modelWidth / 2) + CORNER_EMPTY_WALL_SIZE_X &&
      Math.abs(distancePositiveX) < wallWidth &&
      Math.abs(distanceNegativeX) < wallWidth
    ) {
      // Якщо модель перебуває в діапазоні бічних стін, то додаємо в об'єкт cornerDistance відстань до поточної стіни
      cornerDistance[wallName] = objDistance[wallName];
    }
  });

  // Повертаємо об'єкт відстаней моделі до кутів
  return cornerDistance;
};