import { ModelsName_NodesT, WallItemT } from "./../../../../utils/constants/nodesNamesThreekit";
import { getTranslationThreekit } from "../../../../utils/threekit/general/getFunctions";
import { moveCoordsByVector } from "../../../wallsAndFloor/buildWallFromData";
import { getLeftRigthDirectionsWall } from "../../../wallsAndFloor/getGeneralWallInfo";
import { getModelsWallNullOnWall } from "../../getNodesCabinets";
import { MoveDirectionOnWallT } from "../../cabinetsBase/moving/moveAllCabinetsBaseOnWall";
import { setTranslationThreekit } from "../../../../utils/threekit/general/setFunctions";
import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { ArrWallRangesT } from "../../../intervals/getIntervalsOnWallForCabinetsWall";
import { getNullNameMovedModelForCornerEmpty } from "../../../../utils/threekit/tools/toolsDragCabinetsBase/generalFunc";

export const moveCabinetWallOnWall = (
  nullNameCabinetWall: ModelsName_NodesT,
  moveDirectionVector: ICoordinates,
  moveDistance: number,
) => {
  const currentTranslation = getTranslationThreekit({
    name: nullNameCabinetWall
  })
  const newTranslation = moveCoordsByVector(
    currentTranslation,
    moveDirectionVector,
    moveDistance
  );
  setTranslationThreekit({
    name: nullNameCabinetWall,
    value: newTranslation,
  })
}

export const moveAllCabinetsWallOnWall = (wallName: WallItemT, moveDirection: MoveDirectionOnWallT, moveDistance: number) => {
  const cabinetsWallOnWall = getModelsWallNullOnWall(wallName);
  const { leftDirectionWall, rightDirectionWall } = getLeftRigthDirectionsWall(wallName);

  const moveDirectionOnWall = moveDirection === "left" ? leftDirectionWall : rightDirectionWall;

  cabinetsWallOnWall.forEach((cabinetWallNullName) => {
    
    moveCabinetWallOnWall(
      cabinetWallNullName,
      moveDirectionOnWall,
      moveDistance
    );

  })

}

export const moveCabinetWallOnWallForEmptyCorner = (
  wallName: WallItemT,
  wallIntervals: ArrWallRangesT,
  moveDirection: MoveDirectionOnWallT,
  moveDistance: number
) => {

  const nullNameMovedModel =  getNullNameMovedModelForCornerEmpty(
    wallIntervals,
    moveDirection,
  );

  if (nullNameMovedModel === "") return;

  const { leftDirectionWall, rightDirectionWall } = getLeftRigthDirectionsWall(wallName);
  const moveDirectionOnWall =
    moveDirection === "left"
      ? leftDirectionWall
      : rightDirectionWall;

  moveCabinetWallOnWall(
    nullNameMovedModel,
    moveDirectionOnWall,
    moveDistance
  )

}