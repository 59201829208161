import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import {
  getStatusDependencySettings,
  getValueOption,
} from "../../../store/selectors/settingsUISelectors";
import { updateSettings } from "../../../store/slices/settingsUI/settingsUISlice";
import {
  setConfigurationForOneCabinetType,
  setDoorStyleForAllCabinetsType,
} from "../../../functionsConfigurator/cabinets/configuration/stylingCabinets";
import { BaseSelectColor } from "../../BaseComponent/SelectColor/BaseSelectColor";
//@ts-ignore
import s from "./SelectColor.module.scss";
import { updateSetingsUIAndThreekit } from "../../../store/actions/player.action";

type valueOptionSelectColor = {
  selectValue: string;
  isError: boolean;
};

export const SelectColor = ({ ...props }) => {
  const {
    id,
    label,
    values,
    defaultValue,
    visibilityState,
    dependencySettings,
    disabled
  } = props;
  const dispatch = useAppDispatch();
  const selectValue = useAppSelector(getValueOption({ idOption: id }));

  const onChange = () => (value: string) => {
    // if (id.includes("AllCabinets")) {
    //   setDoorStyleForAllCabinetsType(value, id);
    // } else {
    //   setConfigurationForOneCabinetType(id, value);
    // }
    // dispatch(updateSettings([{ optionId: id, value: value }]));
    dispatch(updateSetingsUIAndThreekit([{optionId: id, value: value}]))
  };

  const isVisibleOption = useAppSelector(
    getStatusDependencySettings(dependencySettings)
  );

  if (!isVisibleOption) return <></>;

  return (
    <div className={s.boxSelectColor}>
      {label && (
        <div className={s.selectColorWrapLabel}>
          <div className={s.label}>{label}</div>
        </div>
      )}
      <BaseSelectColor
        values={values}
        onChange={onChange()}
        value={selectValue}
        errorComponent={false}
        disabled={disabled}
      />
    </div>
  );
};
