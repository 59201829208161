import { isCornerMirrorCabinet } from "../../../../functionsConfigurator/cabinets/checkModels";
import { useAppSelector } from "../../../../hooks/useStoreHooks";
import { getImgLinkForProduct } from "../../../../store/selectors/settingsUISelectors";
import { ProductI } from "../../../../utils/constants/cabinets";
import { THREEKIT_IMAGES_URL } from "../../../../utils/threekit/threekitConfig/threekitConfig";
import { ButtonMain } from "../../Buttons/ButtonMain";
import NotImage from  "./../../../../assets/images/Cabinets/not_image.png";

// scss
import s from "./Option.module.scss"

const IMAGES_URL = THREEKIT_IMAGES_URL;

interface OptionProductI extends ProductI {
  onClick: (idProduct: string) => void;
}

export const Option = ({ ...props }: OptionProductI)  => {
  const { id, value, label, img, doorDirection, size, onClick, disabled, description } = props;

  const handleClick = () => {
    onClick(id)
  }

  const imgLink = useAppSelector(getImgLinkForProduct(id));
  const optionImg = imgLink !== "" && imgLink !== "https://thumbnail_xyz" ? `${IMAGES_URL}${imgLink}` : NotImage;

  return (
    <div className={`${s.option} ${!!disabled && disabled ? s.disabled : ""}`}>
      <div className={s.topContent}>
        <div className={s.optionImg}>
          <div className={s.imgWrap}>
            <img src={optionImg} alt={label} className={`${isCornerMirrorCabinet(id) && s.scaleX}`} />
          </div>
        </div>
        <p className={s.optionLabel}>{label}</p>
      </div>
      <div className={s.bottomContent}>
        <p className={s.optionDescription}>{description ? description : "-"}</p>
        <p className={s.optionSize}>{`${size["width"]}” (W) x ${size["height"]}”(H) x ${size["depth"]}”(D)`}</p>
        <ButtonMain text="Add" onClick={handleClick} color="blueDark" size="small" fullWidth={true} />
      </div>
    </div>
  );
};
