// images
import { RadioOptionI } from "../../types/UI/dynamic-rendering.interfaces"
import HomeDepot from "./../../assets/images/Modals/Distributor/Home_Depot.png"
import Lowes from "./../../assets/images/Modals/Distributor/Lowes.png"
import Wayfair from "./../../assets/images/Modals/Distributor/Wayfair.png"

export const DistributorsOptions: RadioOptionI[] = [
  {
    id: Math.floor(100000 + Math.random() * 900000),
    value: "Lowes",
    label: "Lowes",
    img: Lowes,
  },
]