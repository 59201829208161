import { debounce } from "lodash";
import { ModelCabinetBaseT, ModelCabinetWallT, NODES_THREEKIT, PlaneCabinetsWallT, WallItemT } from "../../../utils/constants/nodesNamesThreekit";
import { getNumberNodeThreekitFromName } from "../../general";
import { getModelsWallNullOnPlane } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { getModelsBaseNullOnWall } from "../getNodesCabinets";

/**
 * Функція шукає моделі Cabinets Base, які встановлені безпосередньо біля обраних стін. НАПОЛЬНІ МОДЕЛІ(Cabinets Base).
 *
 * @param {WallItemT[]} arrWallsNames Масив імен стін з Threekit, біля яких шукаємо напольні моделі.
 * @return {ModelCabinetBaseT[]} Массив з іменами Threekit, для Cabinets Base Models Null ["Model_Cabinets_Base_0", ...].
 */
export const getArrModelsBaseNullOnWalls = (arrWallsNames: WallItemT[]): ModelCabinetBaseT[] => {
  let arrModelsWallNullOnWalls: ModelCabinetBaseT[] = [];
  arrWallsNames.forEach((wallName) => {
    const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallName) as ModelCabinetBaseT[];
    arrModelsWallNullOnWalls = [
      ...arrModelsWallNullOnWalls,
      ...modelsBaseNullOnWall
    ]
  })
  return arrModelsWallNullOnWalls;
}

/**
 * Функція дозволяє або забороняє переміщення по осям X та Z для однієї напольної шафи.
 * Встановлює значення "Physics" для моделей в Threekit
 *
 * @param {ModelCabinetBaseT} nullNameCabinetBase Name для Cabinets Base Models Null ["Model_Cabinets_Base_0", ...].
 * @param {boolean} value Нове значення для властивостей lockTX та lockTZ.
 */
export const setLockTranslationCabinetBase = (nullNameCabinetBase: ModelCabinetBaseT,  value: boolean) => {
  window.threekit.player.scene.set({
    name: nullNameCabinetBase,
    //@ts-ignore
    plug: "Physics",
    properties: {
        name: "Rigid Body"
    },
    //@ts-ignore
    property: 'lockTX'
  }, value);
  window.threekit.player.scene.set({
    name: nullNameCabinetBase,
    //@ts-ignore
    plug: "Physics",
    properties: {
        name: "Rigid Body"
    },
    //@ts-ignore
    property: 'lockTZ'
  }, value);
}

/**
 * Функція дозволяє або забороняє переміщення по осям X та Z для напольних шкафів.
 * Встановлює значення "Physics" для моделей в Threekit
 *
 * @param {ModelCabinetBaseT[]} arrNamesNullForModelsWall Массив з іменами Threekit, для Cabinets Base Models Null ["Model_Cabinets_Base_0", ...].
 * @param {boolean} value Нове значення для властивостей lockTX та lockTZ.
 */
export const setLockTranslationCabinetsBase = (arrNamesNullForModelsBase: ModelCabinetBaseT[],  value: boolean) => {
  arrNamesNullForModelsBase.forEach((nameModelNull) => {
    setLockTranslationCabinetBase(nameModelNull, value);
  })
}

/**
 * Функція отримує моделі Cabinets Base, які встановлені безпосередньо біля обраних стін.
 * Та викликає функції, які дозволяють або забороняють переміщення по осям X та Z напольних шкафів вздовж стін.
 *
 * @param {WallItemT[]} arrNamesVisibleWalls Массив імен стін, які є видимими.
 * @param {WallItemT[]} arrNamesHiddenWalls Массив імен стін, які є прихованими.
 */
export const debouncedCheckLockTranslationCabinetsBase = debounce((arrNamesVisibleWalls: WallItemT[], arrNamesHiddenWalls: WallItemT[]) => {
  
  let arrNamesModelsUnlockTranslation = getArrModelsBaseNullOnWalls(arrNamesVisibleWalls);
  let arrNamesModelsLockTranslation = getArrModelsBaseNullOnWalls(arrNamesHiddenWalls);

  setLockTranslationCabinetsBase(arrNamesModelsUnlockTranslation, false);
  setLockTranslationCabinetsBase(arrNamesModelsLockTranslation, true);

}, 300);