import React from "react";

export const Boundary = () => {
  return (
    <svg
      width="90"
      height="42"
      viewBox="0 0 90 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M71 17V25L84 25V17L71 17Z" fill="#0073AE" />
      <path d="M67 17L63 17V25H67V17Z" fill="#0073AE" />
      <path d="M55 17L59 17V25H55V17Z" fill="#0073AE" />
      <path d="M51 17L47 17V25L51 25V17Z" fill="#0073AE" />
      <path d="M39 17L43 17V25H39L39 17Z" fill="#0073AE" />
      <path d="M35 17L31 17L31 25H35L35 17Z" fill="#0073AE" />
      <path d="M23 17L27 17L27 25L23 25V17Z" fill="#0073AE" />
      <path d="M19 17L6 17L6 25L19 25L19 17Z" fill="#0073AE" />
      <circle cx="8" cy="21" r="5.5" fill="white" stroke="#0073AE" />
      <circle cx="8" cy="21" r="2" fill="#0073AE" />
      <circle cx="82" cy="21" r="5.5" fill="white" stroke="#0073AE" />
      <circle cx="82" cy="21" r="2" fill="#0073AE" />
    </svg>
  );
};
