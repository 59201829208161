// scss
import s from "./Logo.module.scss";

// images
import MainMenuLogo from "./../../assets/images/Scott Living 2021_Logo-Horiz.png";
import WhalenClickItLogo from "./../../assets/images/Whalen_Click_It_Logo_White.png";

export const Logo = () => {
  return (
    <div className={s.mainLogo}>
      <img src={WhalenClickItLogo} alt="Whalen Click-It" />
    </div>
  );
};
