import {
  ModelAppliancesT,
  ModelCabinetIslandT,
} from "../../../utils/constants/nodesNamesThreekit";
import {
  getRotationThreekit,
  getTranslationThreekit,
  getWorldTransformEvalNode,
} from "../../../utils/threekit/general/getFunctions";
import {
  setRotationThreekit,
  setTranslationThreekit,
} from "../../../utils/threekit/general/setFunctions";
import * as THREE from "three";
import { moveCoordsByVector } from "../../wallsAndFloor/buildWallFromData";
import { getSizeModelRelativeTransform } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { getVector3FromCoordinates } from "../../../utils/three/general/getFunctionsTHREE";

const getNewRotationModel = (
  modelNullName: ModelCabinetIslandT | ModelAppliancesT
): THREE.Vector3 => {
  const currentRotation = getRotationThreekit({ name: modelNullName });
  const indexRotation = Math.abs(currentRotation["x"]) !== 180 ? -1 : 1;
  const rotationY = Math.round(currentRotation["y"]) + 90 * indexRotation;
  const newRotation = new THREE.Vector3(0, rotationY, 0);
  return newRotation;
};

const getNewPositionModel = (
  modelNullName: ModelCabinetIslandT | ModelAppliancesT
): THREE.Vector3 => {
  const positionModel = getTranslationThreekit({ name: modelNullName });
  const sizeCabinet = getSizeModelRelativeTransform(modelNullName);
  const worldTransformCabinet = getWorldTransformEvalNode(modelNullName);
  const cabinetDirFront = new THREE.Vector3(0, 0, 1).transformDirection(
    worldTransformCabinet
  );
  const cabinetDirRight = new THREE.Vector3(1, 0, 0).transformDirection(
    worldTransformCabinet
  );
  let newPositionCabinet = moveCoordsByVector(
    positionModel,
    cabinetDirRight,
    sizeCabinet["z"] / 2
  );
  newPositionCabinet = moveCoordsByVector(
    newPositionCabinet,
    cabinetDirFront,
    sizeCabinet["z"] / 2
  );

  return getVector3FromCoordinates(newPositionCabinet);
};

export const rotateCabinetsIsland = (
  modelNullName: ModelCabinetIslandT | ModelAppliancesT
) => {
  if (!!!modelNullName) return undefined;

  const newRotationModel = getNewRotationModel(modelNullName);
  const newPositionModel = getNewPositionModel(modelNullName);

  setRotationThreekit({
    name: modelNullName,
    value: newRotationModel,
  });

  setTranslationThreekit({
    name: modelNullName,
    value: { ...newPositionModel, y: 0 },
  });
};
