import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalMessageToeKick.module.scss";

export const ModalMessageToeKick = ({ ...props }) => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.MESSAGE_TOE_KICK));
  const dispatch = useAppDispatch();

  const handleContinue = () => {
    dispatch(setModalVisible({ modalId: MODAL_KEYS.MESSAGE_TOE_KICK, value: false, isFirstShow: true }));
  };

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.MESSAGE} zIndex={1005}>
      <div className={s.modalMessage}>
        <p className={s.title}>
          Toe kicks are optional but recommended. The length you need to complete your project will automatically appear
          on your Shopping List.
        </p>

        <div className={s.btnsWrap}>
          <div className={s.col}>
            <ButtonMain
              text="Continue"
              onClick={handleContinue}
              color="blueDark"
              size="bigSuper"
              fullWidth={true}
              bold={true}
            />
          </div>
        </div>
      </div>
    </ModalsWrap>
  );
};
