import React, { useState, useEffect, useLayoutEffect } from "react";
import s from "./InputFeetAndInches.module.scss";
import { InperialUnitsI, convertFeetAndInchesToMeters, convertMetersToFeetAndInches } from "../../../functionsConfigurator/configurator2D/units";
import { useDebouncedFunction } from "../../../hooks/useDebouncedFunction";

type InputFeetAndInchesT = {
  valueDefault: any;
  onCountChange: (value: number) => void;
};

export interface InperialUnitsStringI {
  feet: string;
  inches: string;
}

const InputFeetAndInches = ({
  valueDefault,
  onCountChange,
}: InputFeetAndInchesT) => {

  const [imperialValue, setImperialValue] = useState<InperialUnitsI>({feet: 0, inches: 0});
  const [errorFeetField, setErrorFeetField] = useState<boolean>(false);
  const [errorInchesField, setErrorInchesField] = useState<boolean>(false);

  const updateThreekit = (newImperialValue: InperialUnitsI) => {
    if (
      onCountChange !== undefined &&
      // typeof newImperialValue["feet"] === "number" &&
      !Number.isNaN(newImperialValue["feet"]) &&
      // typeof newImperialValue["inches"] === "number" &&
      !Number.isNaN(newImperialValue["inches"])
    ) {
      onCountChange(convertFeetAndInchesToMeters(newImperialValue));
      setImperialValue(newImperialValue);
    }
  }

  const handleFeetChange = (value: string) => {
    const newValue = parseFloat(value);
    const newImperialValue = {
      feet: newValue,
      inches: imperialValue["inches"]
    };
    updateThreekit(newImperialValue);

    // if (
    //   onCountChange !== undefined &&
    //   typeof newValue === "number" &&
    //   !Number.isNaN(newValue)
    // ) {
    //   const newImperialValue = {
    //     feet: newValue,
    //     inches: imperialValue["inches"]
    //   };
    //   onCountChange(convertFeetAndInchesToMeters(newImperialValue));
    //   // setImperialValue(newImperialValue);
    // } else {
    //   const newImperialValue = {
    //     feet: 1,
    //     inches: imperialValue["inches"]
    //   };
    //   onCountChange(convertFeetAndInchesToMeters(newImperialValue));
    //   setImperialValue(newImperialValue);
    // }
  };

  const debouncedFeetValueDispatching = useDebouncedFunction(
    handleFeetChange,
    500
  );

  function onChangeFeetValue(event: React.ChangeEvent<HTMLInputElement>) {
    const numericValue = event.target.value.replace(/\D/g, ''); // видаляємо все, крім цифр
    const newImperialValue = {
      feet: numericValue,
      inches: imperialValue["inches"]
    };
    setImperialValue(newImperialValue);
    debouncedFeetValueDispatching(numericValue);
  }

  const handleInchesChange = (value: string) => {
    const newValue = parseFloat(value);
    let newImperialValue = {
      feet: imperialValue["feet"],
      inches: newValue
    };

    if (newValue >= 12) {
      newImperialValue.feet = Number(imperialValue["feet"]) + Math.floor(newValue / 12);
      newImperialValue.inches = newValue % 12;
    }

    updateThreekit(newImperialValue);

    // if (
    //   onCountChange !== undefined &&
    //   typeof newValue === "number" &&
    //   !Number.isNaN(newValue)
    // ) {

    //   let newImperialValue = {
    //     feet: imperialValue["feet"],
    //     inches: newValue
    //   };

    //   if (newValue >= 12) {
    //     newImperialValue.feet = Number(imperialValue["feet"]) + Math.floor(newValue / 12);
    //     newImperialValue.inches = newValue % 12;
    //   }

    //   onCountChange(convertFeetAndInchesToMeters(newImperialValue));
    //   setImperialValue(newImperialValue);
    // } else {
    //   const newImperialValue = {
    //     feet: imperialValue["feet"],
    //     inches: 0,
    //   };
    //   onCountChange(convertFeetAndInchesToMeters(newImperialValue));
    //   setImperialValue(newImperialValue);
    // }
  };

  const debouncedInchesValueDispatching = useDebouncedFunction(
    handleInchesChange,
    500
  );

  function onChangeInchesValue(event: React.ChangeEvent<HTMLInputElement>) {
    const numericValue = event.target.value.replace(/,+/g, '.').replace(/[^0-9.]/g, '');
    const newImperialValue = {
      feet: imperialValue["feet"],
      inches: numericValue
    };
    setImperialValue(newImperialValue);
    debouncedInchesValueDispatching(numericValue);
  }

  useLayoutEffect(() => {
    const valueFeetInches = convertMetersToFeetAndInches(valueDefault);
    setImperialValue({
      feet: String(valueFeetInches["feet"]),
      inches: String(valueFeetInches["inches"]),
    });
  }, []);

  useEffect(() => {

    if (imperialValue["feet"] === "") {
      setErrorFeetField(true);
    } else {
      setErrorFeetField(false);
    }

    if (imperialValue["inches"] === "") {
      setErrorInchesField(true);
    } else {
      setErrorInchesField(false);
    }

  }, [imperialValue]);

  return (
    <div className={s.container}>
      <div className={s.inputs}>
        <div className={s.boxNumber}>
          <input
            type="text"
            id="feet"
            name="feet"
            value={imperialValue["feet"]}
            onChange={onChangeFeetValue}
            className={`${errorFeetField ? s.error : ""}`}
          />
          <span>ft</span>
        </div>
        <div className={s.boxNumber}>
          <input
            type="text"
            id="inches"
            name="inches"
            value={imperialValue["inches"]}
            onChange={onChangeInchesValue}
            className={`${errorInchesField ? s.error : ""}`}
          />
          <span>inch</span>
        </div>
      </div>
    </div>
  );
};

export default InputFeetAndInches;
