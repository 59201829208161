import { valueActiveOptionT } from "../../store/slices/settingsUI/typesSettingsUISlice";
import { PRODUCT_POSITIONS_KEYS } from "../../utils/constants/cabinets";

export const convertValuePositionCabinetToLabel = (valuePositionCabinet: valueActiveOptionT) => {
  switch (valuePositionCabinet) {
    case PRODUCT_POSITIONS_KEYS.BASE_CABINET:
      return "Base Cabinets";
    case PRODUCT_POSITIONS_KEYS.WALL_CABINET:
      return "Wall Cabinets";
    default:
      return valuePositionCabinet
  }
}