import { IConfiguration } from "@threekit-tools/treble/dist/types";
import { CabinetsAndFeatures_NodesT } from "../../utils/constants/nodesNamesThreekit";
import { getConfiguratorModelFromNullName } from "./configuration/decorativePanel";

function copyProperties(source: any, target: any, properties: string[]) {
  for (const prop of properties) {
    if (prop in source) {
      target[prop] = source[prop];
    }
  }
}

export const getClonedConfiguration = (modelName: CabinetsAndFeatures_NodesT): IConfiguration => {
  const configuratorModel = getConfiguratorModelFromNullName(modelName);
  const currentFullConfiguration = configuratorModel.getFullConfiguration();
  let clonedConfiguration: IConfiguration = {};
  copyProperties(currentFullConfiguration, clonedConfiguration, [
    "Cabinet material",
    "Door Knob Location",
    "isVisibleConnectorsCabinetsBase",
    "isVisibleConnectorsCabinetsIsland",
    "Sink",
  ]);
  return clonedConfiguration;
};
