// images
import { RadioOptionI } from "../../types/UI/dynamic-rendering.interfaces";
import Moulding_3 from "./../../assets/images/OtherParamsImages/Moulding/Moulding_3.svg";
import Moulding_6 from "./../../assets/images/OtherParamsImages/Moulding/Moulding_6.svg";
// import { RadioOptionI } from "./framedOptions";

export const mouldingTopOptions: RadioOptionI[] = [
  {
    id: Math.floor(100000 + Math.random() * 900000),
    value: "3in",
    label: "3” Height",
    img: Moulding_3,
  },
  // {
  //   id: Math.floor(100000 + Math.random() * 900000),
  //   value: "6in",
  //   label: "6” Height",
  //   img: Moulding_6,
  // },
]