// scss
import s from "./Header.module.scss";

// images
import WhalenClickItLogo from "./../../../../../assets/room-list/Whalen_Click_It_Logo_Blue_Black.png";

export const Header = () => {
  return (
    <div className={s.contentProducts_head}>
      <div className={s.mainLogo}>
        <img src={WhalenClickItLogo} alt="Whalen Click-It" />
      </div>
      <div className={s.title}>Drawing Summary and Bill of Material</div>
    </div>
  );
};
