// import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getValueOption } from "../../../store/selectors/settingsUISelectors";
// import { RadioOptionI } from "../../../utils/constants/framedOptions";
import { RadioOption } from "./RadioOption/RadioOption";

// scss
import s from "./RadioOptions.module.scss";
import { updateSetingsUIAndThreekit } from "../../../store/actions/player.action";
import { RadioOptionI } from "../../../types/UI/dynamic-rendering.interfaces";

type valueOptionRadioOptions = {
  selectValue: string;
  isError: boolean;
};

export const RadioOptions = ({ ...props }) => {
  const { id, label, values, defaultValue, sectionId } = props;

  const dispatch = useAppDispatch();
  const selectValue = useAppSelector(
    getValueOption({ idOption: id, sectionId: sectionId })
  );

  const onChange = () => (value: string) => {
    // if (id.includes("AllCabinets")) {
    //   setDoorStyleForAllCabinetsType(value, id);
    // } else {
    //   setConfigurationForOneCabinetType(id, value);
    // }

    // dispatch(
    //   updateSettings([
    //     { optionId: id, value: value, parentSectionId: sectionId },
    //   ])
    // );

    dispatch(updateSetingsUIAndThreekit([{optionId: id, value: value, parentSectionId: sectionId}]))
  };

  return (
    <div className={s.radioOptionsWrap}>
      {label && <p className={s.radioOptionsLabel}>{label}</p>}
      <div className={s.radioOptionsList}>
        {values.map((option: RadioOptionI) => {
          const isActiveOption = option["value"] === selectValue;
          return (
            <RadioOption
              key={option["id"]}
              {...option}
              onClick={onChange()}
              isActive={isActiveOption}
            />
          );
        })}
      </div>
    </div>
  );
};
