import { ModelsName_NodesT, NODES_THREEKIT, PlaneCabinetsWallT, WallItemT } from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import { getInstanceIdAssetFromNullModel, getNameNodeThreekit } from "../../../utils/threekit/general/getFunctions";
import { isFeaturesModelNullName } from "../../features/general";
import { getNumberNodeThreekitFromName } from "../../general";
import { checkIntersectIntervalFromIntervals } from "../../intervals/intersectIntervals";
import { getIntervalsWallCabinetsForAllWalls } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { AllWallsIntervalsFloorT, getIntervalsBaseCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWallBase";
import { updateConnectorsVerticalCabinetsBase, updateConnectorsVerticalCabinetsWall } from "../cabinetsBase/checkCabinetsBaseVerticalConnectors";

const getFilteredIntervals = (
  nullNameSelectedCabinetBase: string,
  planeName: PlaneCabinetsWallT,
  intervalsCabinetsBaseForAllWalls: AllWallsIntervalsFloorT
) => {
  const nameWallCabinetsBase = `${
    NODES_THREEKIT.WALL_ITEM
  }${getNumberNodeThreekitFromName(planeName)}` as WallItemT;
  const arrWallIntervalsCabinetsBase =
    intervalsCabinetsBaseForAllWalls[nameWallCabinetsBase];
  const filteredIntervalsCabinetsBaseNotSelected =
    arrWallIntervalsCabinetsBase.filter((objIntervalCabinetsWall) => {
      return objIntervalCabinetsWall["name"] !== nullNameSelectedCabinetBase;
    });
  return filteredIntervalsCabinetsBaseNotSelected;
};

export const checkCabinetsWallVerticalConnectors = (
  idSelectedCabinetBase: string
) => {
  
  const nullNameSelectedCabinetBase = getNameNodeThreekit({
    id: idSelectedCabinetBase,
  });

  const instanceIdAssetCabinetBase = getInstanceIdAssetFromNullModel({
    name: nullNameSelectedCabinetBase,
  });
  updateConnectorsVerticalCabinetsBase(instanceIdAssetCabinetBase, true);

  const intervalsCabinetsBaseForAllWalls =
    getIntervalsBaseCabinetsForAllWalls();
  const intervalsCabinetsWallForAllWalls =
    getIntervalsWallCabinetsForAllWalls();

  const keysIntervalsCabinetsWallForAllWalls = getKeys(
    intervalsCabinetsWallForAllWalls
  );

  keysIntervalsCabinetsWallForAllWalls.forEach((planeName) => {

    const arrWallIntervalsCabinetsWall =
      intervalsCabinetsWallForAllWalls[planeName];

    const filteredIntervalsCabinetsBaseNotSelected = getFilteredIntervals(
      nullNameSelectedCabinetBase,
      planeName,
      intervalsCabinetsBaseForAllWalls
    );

    arrWallIntervalsCabinetsWall.forEach((interval, indx) => {
      let { empty, range, name } = interval;

      if (!empty && name !== undefined && !isFeaturesModelNullName(name)) {
        const nullNameCabinetWall = name as ModelsName_NodesT;
        const rangeCabinetWall = range;

        const isIntersectsIntervals = checkIntersectIntervalFromIntervals(
          rangeCabinetWall,
          filteredIntervalsCabinetsBaseNotSelected
        );

        const instanceIdAssetCabinetWall = getInstanceIdAssetFromNullModel({
          name: nullNameCabinetWall,
        });

        if (isIntersectsIntervals) {
          updateConnectorsVerticalCabinetsWall(
            instanceIdAssetCabinetWall,
            false
          );
        } else {
          updateConnectorsVerticalCabinetsWall(
            instanceIdAssetCabinetWall,
            true
          );
        }
      }
    });
  });

}