import { ICoordinates } from "@threekit-tools/treble/dist/types";
import {
  ModelsName_NodesT,
  WallItemT,
} from "../../../../utils/constants/nodesNamesThreekit";
import {
  ModelCabinetBaseT,
  NODES_THREEKIT,
} from "../../../../utils/constants/nodesNamesThreekit";
import {
  getBoxWidthThreekit,
  getRotationThreekit,
  getSceneInstanceId,
} from "../../../../utils/threekit/general/getFunctions";
import {
  setRotationThreekit,
  setTranslationThreekit,
} from "../../../../utils/threekit/general/setFunctions";

import { getNumberNodeThreekitFromName } from "../../../general";
import {
  ArrWallRangesT,
  WallRangeT,
  addIntervalToArrIntervals,
  checkCornerIntervalEmpty,
  getIntervalForModel,
} from "../../../intervals/getIntervalsOnWallForCabinetsWall";
import { getIntervalsInfoOnWallForCabinetsBase } from "../../../intervals/getIntervalsInfoOnWallBase";
import {
  getAllWallsNode,
  moveCoordsByVector,
} from "../../../wallsAndFloor/buildWallFromData";
import {
  getLeftRigthDirectionsPlane,
  getNeighborPlanes,
  getPlaneNameFromWallName,
  getStartEndCoordsPlane,
} from "../../../wallsAndFloor/getWallPlanesInfo";
import { getModelsBaseNullOnWall } from "../../getNodesCabinets";
import { ActiveAndNewValuesThreekitT } from "../../getObjActiveAndNewValuesAttributeThreekit";
import { getWallNameForCabinet } from "../../getWallNameForCabinet";
import { getSizeModelBoxFromAssetCabinetBase } from "../size";
import { getСompletedModelsNullNames } from "./../../getNodesCabinets";
import { checkIntervalForModel } from "../../../intervals/generalIntervals";
import { getTargetWallNameForAddedOneCabinetBase } from "../getWallForAddedCabinetsBase";
import { isNullNameModelCabinetBaseT } from "../checkTypeCabinetsBase";
import { NeaborIntervalsI, getNeaborIntervals } from "../../../intervals/getNeaborIntervals";
import { getIntervalsForPositionedNewCabinetBase } from "../../../intervals/intervalsInfoForPositionedNewCabinetsBase";
import { checkWallVisibilityFromCamera } from "../../../wallsAndFloor/getGeneralWallInfo";
import { setLockTranslationCabinetBase } from "../checkLockTranslationCabinetsBase";

const getPositionForNewModelFromInterval = (
  sizeCabinetBaseNew: ICoordinates,
  objInterval: WallRangeT,
  side: "left" | "right"
): number => {
  if (side === "left") {
    return objInterval["range"][1] - sizeCabinetBaseNew["x"] / 2;
  } else {
    return objInterval["range"][0] + sizeCabinetBaseNew["x"] / 2;
  }
};

/**
 * Перевіряє сусідні інтервали для моделі, яка вже знаходиться в правильній позиції на стіні:
 * 1) Чи інтервал пустий
 * 2) Чи розмір інтервалу підходить для додавання нової моделі
 * Повертає відстань від початку плейну до точки, в якій повинен розміститися центр нової моделі
 * (позицію на плейні для встановлення моделі або undefined)
 *
 * @param {NeaborIntervalsCabinetBaseI | null} neaborIntervals Об'єкт, який описує сусідні інтервали для обраної моделі на стіні.
 * @param {ICoordinates} sizeCabinetBaseNew Розмір моделі, яку потрібно зпозиціонувати на стіні.
 * @return {number | undefined} Позиція на проміжку плейну для позиціонування нової моделі поруч з обраною моделлю.
 * Або undefined - якщо поруч з обраною моделлю немає місця
 */
const getPositionOnWallForNewCabinetBase = (
  neaborIntervals: NeaborIntervalsI | null,
  sizeCabinetBaseNew: ICoordinates
): number | undefined => {
  if (neaborIntervals === null) return undefined;

  if (
    checkIntervalForModel(
      neaborIntervals["leftNeabor"],
      sizeCabinetBaseNew["x"]
    )
  ) {
    return getPositionForNewModelFromInterval(
      sizeCabinetBaseNew,
      neaborIntervals["leftNeabor"] as WallRangeT,
      "left"
    );
  }

  if (
    checkIntervalForModel(
      neaborIntervals["rightNeabor"],
      sizeCabinetBaseNew["x"]
    )
  ) {
    return getPositionForNewModelFromInterval(
      sizeCabinetBaseNew,
      neaborIntervals["rightNeabor"] as WallRangeT,
      "right"
    );
  }
};

/**
 * Повертає координати для нової моедлі на стіні
 *
 * @param {WallItemT} wallName Name стіни, на якій знайдено підходящий проміжок для нової моделі.
 * @param {number} offsetDistance Позиція на проміжку плейну для позиціонування нової моделі (відстань від початку плейну до точки, в якій повинен розміститися центр нової моделі).
 * @return {ICoordinates} Тривимірні координати, для встановлення нової моделі в цю позицію на стіні.
 */
const getCoordsNewCabinetBaseOnWall = (
  wallName: WallItemT,
  offsetDistance: number
): ICoordinates => {
  const currentPlaneName = getPlaneNameFromWallName(wallName);
  const { leftDirectionPlane, rightDirectionPlane } =
    getLeftRigthDirectionsPlane(currentPlaneName);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(currentPlaneName);

  return moveCoordsByVector(
    { ...currentPlaneCoordsLeft, y: 0 },
    rightDirectionPlane,
    offsetDistance
  );
};

/**
 * Шукає та повертає кут повороту для нової моделі.
 * Кут отримується з значення Rotation для плейну, на якому повинен розташовуватися новий шкаф
 *
 * @param {WallItemT} wallName Name стіни, на якій знайдено підходящий проміжок для нової моделі.
 * @return {ICoordinates} Кут повороту для нової моделі (по осі Y).
 */
const getRotationNewCabinetBaseOnWall = (wallName: WallItemT): ICoordinates => {
  const currentPlaneName = getPlaneNameFromWallName(wallName);
  return getRotationThreekit({
    name: currentPlaneName,
  });
};

/**
 * Позиціонує та обертає нову модель на стіні, відповідно до переданих в функцію параметрів.
 * Кут отримується з значення Rotation для плейну, на якому повинен розташовуватися новий шкаф
 *
 * @param {ModelsName_NodesT} nullNameCabinetBaseNew Null Name нової моделі.
 * @param {ICoordinates} translation Позиція, в яку потрібно встановити нову модель.
 * @param {ICoordinates} rotation Кут повороту (по осі Y) для нової моделі.
 */
const setPositionNewCabinetBase = (
  nullNameCabinetBaseNew: ModelsName_NodesT,
  translation: ICoordinates,
  rotation: ICoordinates
) => {
  setTranslationThreekit({
    name: nullNameCabinetBaseNew,
    value: translation,
  });
  setRotationThreekit({
    name: nullNameCabinetBaseNew,
    value: {
      x: 0,
      y: rotation["y"],
      z: 0,
    },
  });
};

/**
 * Дозволяє або забороняє переміщення шафи, в залежності від того, на видиму чи приховану стіну додається нова шафа.
 *
 * @param {WallItemT} wallName Name стіни, на якій знайдено підходящий проміжок для нової моделі.
 * @param {ModelCabinetBaseT} nullNameCabinetBaseNew Null Name нової моделі.
 */
const checkMovedNewCabinetBase = (
  wallName: WallItemT,
  nullNameCabinetBaseNew: ModelCabinetBaseT,
) => {
  const isVisibleWall = checkWallVisibilityFromCamera(wallName);
  if (!isVisibleWall)
    setLockTranslationCabinetBase(nullNameCabinetBaseNew, true)
};

/**
 * Рекурсивна функція.
 * Позиціонує нову модель поруч з вже доданими моделями,
 * відносно того чи є поруч з вже доданими моделями пусті проміжки, в які поміститься нова модель.
 * Та повертає статус, вдалося правильно зпозиціонувати модель на стіні чи ні
 *
 * @param {ModelsName_NodesT} nullNameCabinetBaseNew Null Name нової моделі.
 * @param {ICoordinates} sizeCabinetBaseNew Розміри нової моделі.
 * @param {ModelsName_NodesT[]} oldListNullNamesCabinetsBase Масив Cabinets Base, які були додані раніше (вже зпозиціоновані на стінах).
 * @return {boolean} true - модель правильно зпозиціонована на стіні; false - модель не вcтановлена в правильну позицію на стінах
 */
const setPositionNewModelRecursive = (
  nullNameCabinetBaseNew: ModelCabinetBaseT,
  sizeCabinetBaseNew: ICoordinates,
  oldListNullNamesCabinetsBase: ModelsName_NodesT[]
): boolean => {
  // Копіюємо масив вже зпозиціонованих моделей на стінах
  const arrOldCabinetsBaseCopy = oldListNullNamesCabinetsBase.slice(0);

  // Якщо масив пустий то зупиняємо рекурсію, та повертаємо false,
  // що означає що модель не втановлена в правильну позицію
  if (arrOldCabinetsBaseCopy.length === 0) return false;

  // Беремо останюю модель з масиву вже зпозиціонованих моделей на стінах
  const nullNameCabinetBaseOld =
    arrOldCabinetsBaseCopy[arrOldCabinetsBaseCopy.length - 1];

  // 1. Визначаємо стіну, на якій знаходиться модель
  const wallName = getWallNameForCabinet(nullNameCabinetBaseOld);

  // Якщо стіна не знайдена то зупиняємо рекурсію, та повертаємо false,
  if (wallName === undefined) return false;

  // 2. Отримуємо масив інтервалів для стіни, на якій знаходиться модель
  // з урахуванням розташування моделей на сусідніх стінах близько до спільних
  const intervalsForCkeckNewPosition = getIntervalsForPositionedNewCabinetBase(wallName, sizeCabinetBaseNew);

  // 3. Перевіряємо чи є поруч з обраною моделлю місце для нового Cabinet Base (отримуємо обьект сусідніх інтервалів для моделі)
  // 3.1. Отримуємо об'єкт, який описує сусідні інтервали для моделі
  const neaborIntervalsForCabinetBase = getNeaborIntervals(
    intervalsForCkeckNewPosition,
    nullNameCabinetBaseOld
  );

  // 3.2 Перевіряємо сусідні інтервали для моделі
  // чи є пусті інтервали поруч з моделлю, та чи підходять пусті інтервали по розміру
  // в результалі отримуємо позицію на стіні для встановлення моделі або undefined
  const positionOnWallForNewCabinetBase = getPositionOnWallForNewCabinetBase(
    neaborIntervalsForCabinetBase,
    sizeCabinetBaseNew
  );

  // Якщо знайдена позиція для нової моделі поруч з вже доданою моделлю nullNameCabinetBaseOld
  // то встановлюємо модель в цю позицію
  if (positionOnWallForNewCabinetBase !== undefined) {
    const coordsNewCabinetBaseOnWall = getCoordsNewCabinetBaseOnWall(
      wallName,
      positionOnWallForNewCabinetBase
    );
    const rotationNewCabinetBaseOnWall =
      getRotationNewCabinetBaseOnWall(wallName);

    setPositionNewCabinetBase(
      nullNameCabinetBaseNew,
      coordsNewCabinetBaseOnWall,
      rotationNewCabinetBaseOnWall
    );

    checkMovedNewCabinetBase(wallName, nullNameCabinetBaseNew);

    return true;
  }

  // Якщо поруч з останньою доданою моделлю не знайдена позиція для нової моделі,
  // То видаляємо цю модель з масиву.
  // І йдемо на нову рекурсію, де буде перевірена передостання додана модель
  // і т.д., поки не перевіримо всі додані моделі
  arrOldCabinetsBaseCopy.pop();
  return setPositionNewModelRecursive(
    nullNameCabinetBaseNew,
    sizeCabinetBaseNew,
    arrOldCabinetsBaseCopy
  );
};

/**
 * Позиціонує Cabinet Base на стіні в правому куті (так, як наче це перша модель на стіні).
 *
 * @param {ModelCabinetBaseT} nullNameCabinetsBaseNew Null Name нової моделі, яку потрібно поставити у позицію правого кута на стіні.
 * @param {WallItemT} targetWall Name стіни, на якій відбувається позиціонування Cabinet Base в правому куті.
 */
// const setPositionFirstCabinetBaseOnWall = (
//   nullNameCabinetsBaseNew: ModelCabinetBaseT,
//   targetWall: WallItemT
// ) => {
//   const currentWallName = targetWall;
//   const currentPlaneName = getPlaneNameFromWallName(currentWallName);
//   const currentPlaneCabinetsRotation = getRotationThreekit({
//     from: getSceneInstanceId(),
//     name: currentPlaneName,
//   });
//   const { leftDirectionPlane, rightDirectionPlane } =
//     getLeftRigthDirectionsPlane(currentPlaneName);
//   const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
//     getStartEndCoordsPlane(currentPlaneName);

//   const newModelSize = getSizeModelBoxFromAssetCabinetBase(
//     nullNameCabinetsBaseNew
//   );
//   const newModelWidth = newModelSize["x"];
//   const offsetDistance = newModelWidth / 2;

//   const movedCoordsModel = moveCoordsByVector(
//     { ...currentPlaneCoordsRight, y: 0 },
//     leftDirectionPlane,
//     offsetDistance
//   );

//   setPositionNewCabinetBase(
//     nullNameCabinetsBaseNew,
//     movedCoordsModel,
//     currentPlaneCabinetsRotation
//   );
// };

/**
 * Позиціонує Cabinet Base на стіну в перший пустий проміжок, який шукається зправа наліво по стіні та має потрібний розмір
 *
 * @param {ModelCabinetBaseT} nullNameCabinetBaseNew Null Name нової моделі, яку потрібно поставити на стіні.
 * @param {WallItemT} targetWall Name стіни, на яку потрыбно додати нову модель.
 */
const setPositionCabinetOnWallRightToLeft = (
  nullNameCabinetBaseNew: ModelCabinetBaseT,
  targetWall: WallItemT
) => {
  const currentPlaneName = getPlaneNameFromWallName(targetWall);;
  const currentPlaneWidth = getBoxWidthThreekit({ name: currentPlaneName });
  const currentPlaneCabinetsRotation = getRotationThreekit({
    from: getSceneInstanceId(),
    name: currentPlaneName,
  });
  const { leftDirectionPlane, rightDirectionPlane} = 
    getLeftRigthDirectionsPlane(currentPlaneName);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(currentPlaneName);

  const newModelSize = getSizeModelBoxFromAssetCabinetBase(nullNameCabinetBaseNew);
  const newModelWidth = newModelSize["x"];
  let offsetDistance = newModelWidth/2;

  // Отримуємо масив інтервалів для стіни, на якій знаходиться модель
  // з урахуванням розташування моделей на сусідніх стінах близько до спільних
  const intervalsForCkeckNewPosition = getIntervalsForPositionedNewCabinetBase(targetWall, newModelSize);

  const intervalForModel = getIntervalForModel(intervalsForCkeckNewPosition, newModelWidth);
  if (intervalForModel !== undefined) {
    offsetDistance = (currentPlaneWidth - intervalForModel["range"][1]) + newModelWidth/2;
  }

  const movedCoordsModel = moveCoordsByVector(
    { ...currentPlaneCoordsRight, y: 0 },
    leftDirectionPlane,
    offsetDistance
  );

  setPositionNewCabinetBase(
    nullNameCabinetBaseNew,
    movedCoordsModel,
    currentPlaneCabinetsRotation
  );

  checkMovedNewCabinetBase(targetWall, nullNameCabinetBaseNew);

};

/**
 * Позиціонує нові Cabinets Base поруч з вже доданими моделями,
 * відносно того чи є поруч з вже доданими моделями пусті проміжки, в які поміститься нова модель.
 *
 * @param {ActiveAndNewValuesThreekitT} objAssetIdValuesCabinetsBase Об'єкт з старими та новими моделями Cabinets Base,
 * @param {WallItemT} targetWall Стіна, до якої потрібно першочергово додавати шкафи..
 */
export const setPositionNewCabinetsBaseOnWall = (
  objAssetIdValuesCabinetsBase: ActiveAndNewValuesThreekitT,
  targetWall: WallItemT
) => {
  const { activeValues, newValues } = objAssetIdValuesCabinetsBase;

  // Null Names для всіх моделей (старих та щойно доданих)
  const completedNullNamesCabinetsBase = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_BASE
  );

  // Формуємо масив Cabinets Base, які були додані раніше
  const oldListNullNamesCabinetsBase = completedNullNamesCabinetsBase.slice(
    0,
    activeValues.length
  );
  // Формуємо масив нових Cabinets Base, які потрібно розташувати в кімнаті
  const newListNullNamesCabinetsBase = completedNullNamesCabinetsBase.slice(
    activeValues.length
  );

  // Перебираємо масив нових моделей, та позиціонуємо їх на стінах
  newListNullNamesCabinetsBase.forEach((nullNameCabinetBaseNew) => {
    // Первіряємо що nullNameCabinetBaseNew має тип ModelCabinetBaseT
    if (!isNullNameModelCabinetBaseT(nullNameCabinetBaseNew)) return;

    const sizeCabinetBaseNew = getSizeModelBoxFromAssetCabinetBase(
      nullNameCabinetBaseNew
    );

    // Якщо напольних шкафів ще немає, додаємо новий шкаф на стіну targetWall
    if (oldListNullNamesCabinetsBase.length < 1) {
      setPositionCabinetOnWallRightToLeft(nullNameCabinetBaseNew, targetWall);
      oldListNullNamesCabinetsBase.push(nullNameCabinetBaseNew);
      return;
    }

    // Якщо вже є додані Cabinets Base, то шукаємо вільне місце поряд з доданими шкафами
    // Позиціонуємо нову модель відносно попередньо доданих моделей
    const isPositioningNewModel = setPositionNewModelRecursive(
      nullNameCabinetBaseNew,
      sizeCabinetBaseNew,
      oldListNullNamesCabinetsBase
    );

    // Якщо isPositioningNewModel = true, це означає що модель правильно зпозиціонована на стіні
    // Якщо isPositioningNewModel = false, це означає що модель не вcтановлена в правильну позицію на стінах
    // Отже, якщо модель не не вcтановлена в правильну позицію,
    // то шукаємо чи є вільна стіна для додавання нових Cabinet Base
    if (!isPositioningNewModel) {
      const allWallsLength = Object.keys(getAllWallsNode()).length;
      const wallName = getTargetWallNameForAddedOneCabinetBase(
        targetWall,
        getNumberNodeThreekitFromName(targetWall),
        allWallsLength,
        sizeCabinetBaseNew
      );
      if (wallName !== undefined) {
        setPositionCabinetOnWallRightToLeft(nullNameCabinetBaseNew, wallName);
      } else {
        // Цього випадку не може бути, так як наявність місця для нових моделей попередньо перевіряється в міделварі
        // за допомогою функції getWallNameForAddedCabinetsBase
      }
    }

    // Додаємо щойно додану модель до списку oldListNullNamesCabinetsBase
    // Для того, що пошук позиції для наступної моделі починався з щойно доданої
    oldListNullNamesCabinetsBase.push(nullNameCabinetBaseNew);
  });
};

/**
 * СТАРА ФУНКЦІЯ ДЛЯ ДОДАВАННЯ МОДЕЛЕЙ НА СТІНИ В ПОРЯДКУ СПРАВА НАЛІВО
 *
 * При додаванні моделей Cabinets Base (шкафи, що розміщуються на підлозі, але не островні),
 * розміщує моделі таким чином, щоб вони не конфліктувати (не перетинались) одна з одною
 * і розміщувались біля стіни.
 *
 * @param {ActiveAndNewValuesThreekitT} objAssetIdValuesCabinetsWall Об'єкт з массивом вже встановлених моделей в атрибут типу массив - objAssetIdValuesCabinetsWall["activeValues"],
 * та массив нових значень(які потрібно додати на сцену) - objAssetIdValuesCabinetsWall["activeValues"].
 */
export const setPositionCabinetsBaseOnWall = (
  objAssetIdValuesCabinetsWall: ActiveAndNewValuesThreekitT,
  targetWall: WallItemT
) => {
  const { activeValues, newValues } = objAssetIdValuesCabinetsWall;
  // const currentWallName = getWallInFrontCamera();
  const currentWallName = targetWall;
  // const currentPlaneName = getPlaneNameInFrontCamera();
  const currentPlaneName = getPlaneNameFromWallName(currentWallName);
  const currentPlaneWidth = getBoxWidthThreekit({ name: currentPlaneName });
  const { planeLeftName, planeRightName } = getNeighborPlanes(currentPlaneName);
  const currentPlaneCabinetsRotation = getRotationThreekit({
    from: getSceneInstanceId(),
    name: currentPlaneName,
  });
  const { leftDirectionPlane, rightDirectionPlane } =
    getLeftRigthDirectionsPlane(currentPlaneName);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(currentPlaneName);

  const modelsBaseNullOnWall = getModelsBaseNullOnWall(currentWallName);
  let intervalsInfoOnWall: ArrWallRangesT =
    getIntervalsInfoOnWallForCabinetsBase(
      modelsBaseNullOnWall,
      currentPlaneName
    );

  for (
    let i = activeValues.length;
    i < activeValues.length + newValues.length;
    i++
  ) {
    const nextPointName: ModelCabinetBaseT = `${NODES_THREEKIT.MODEL_CABINET_BASE}${i}`;
    // const newModelSize = getSizeModelRelativeTransform(nextPointName);
    const newModelSize = getSizeModelBoxFromAssetCabinetBase(nextPointName);
    // console.log('newModelSize --- ==== ',newModelSize);
    const newModelWidth = newModelSize["x"];
    const newModelDepth = newModelSize["z"];
    const newModelHeight = newModelSize["y"];

    let offsetDistance = newModelWidth / 2;

    // в cornerOffset ще потрібно додати ширину прижимної планки
    const cornerOffset = newModelDepth;
    // якщо позиція нової моделі знаходиться близько до сусідньої стіни зліва
    // то перевіряємо чи не стоїть на сусідній зліва стіні модель в спільному куті
    if (!!planeLeftName) {
      // && offsetDistance <= cornerOffset
      const wallNum = getNumberNodeThreekitFromName(planeLeftName);
      const wallLeftName: WallItemT = `${NODES_THREEKIT.WALL_ITEM}${wallNum}`;
      const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallLeftName);
      const intervalsInfoOnWallLeft = getIntervalsInfoOnWallForCabinetsBase(
        modelsBaseNullOnWall,
        planeLeftName
      );
      const isCornerIntervalEmpty = checkCornerIntervalEmpty(
        intervalsInfoOnWallLeft,
        cornerOffset,
        "right"
      );
      // якщо кут на сусідній стіні зайнятий,
      // то в масив проміжків додаємо новий проміжок розміром ModelDepth + ширина добору
      if (!isCornerIntervalEmpty) {
        const newInterval: WallRangeT = {
          empty: false,
          range: [0, cornerOffset],
        };
        intervalsInfoOnWall = addIntervalToArrIntervals(
          intervalsInfoOnWall,
          newInterval
        );
      }
    }

    // якщо позиція нової моделі знаходиться близько до сусідньої стіни справа
    // то перевіряємо чи не стоїть на сусідній стіні справа модель в спільному куті
    if (!!planeRightName) {
      // && offsetDistance >= currentPlaneWidth - cornerOffset
      const wallNum = getNumberNodeThreekitFromName(planeRightName);
      const wallLeftName: WallItemT = `${NODES_THREEKIT.WALL_ITEM}${wallNum}`;
      const modelsBaseNullOnWall = getModelsBaseNullOnWall(wallLeftName);
      const intervalsInfoOnWallRight = getIntervalsInfoOnWallForCabinetsBase(
        modelsBaseNullOnWall,
        planeRightName
      );
      const isCornerIntervalEmpty = checkCornerIntervalEmpty(
        intervalsInfoOnWallRight,
        cornerOffset,
        "left"
      );
      // якщо кут на сусідній стіні зайнятий,
      // то в масив проміжків додаємо новий проміжок розміром ModelDepth + ширина добору
      if (!isCornerIntervalEmpty) {
        const newInterval: WallRangeT = {
          empty: false,
          range: [currentPlaneWidth - cornerOffset, currentPlaneWidth],
        };
        intervalsInfoOnWall = addIntervalToArrIntervals(
          intervalsInfoOnWall,
          newInterval
        );
      }
    }

    const intervalForModel = getIntervalForModel(
      intervalsInfoOnWall,
      newModelWidth
    );
    if (intervalForModel !== undefined) {
      // offsetDistance = intervalForModel["range"][0] + newModelWidth/2;
      offsetDistance =
        currentPlaneWidth - intervalForModel["range"][1] + newModelWidth / 2;
    }

    // const movedCoordsModel = moveCoordsByVector(
    //   { ...currentPlaneCoordsLeft, y: 0},
    //   rightDirectionPlane,
    //   offsetDistance
    // );
    const movedCoordsModel = moveCoordsByVector(
      { ...currentPlaneCoordsRight, y: 0 },
      leftDirectionPlane,
      offsetDistance
    );

    setTranslationThreekit({
      from: getSceneInstanceId(),
      name: nextPointName,
      value: movedCoordsModel,
    });
    setRotationThreekit({
      from: getSceneInstanceId(),
      name: nextPointName,
      value: {
        x: 0,
        y: currentPlaneCabinetsRotation["y"],
        z: 0,
      },
    });

    // створюємо проміжок для щойно доданої моделі і додаємо його в масив проміжків intervalsInfoOnWall
    // для того щом наступна модель з массиву newValues враховувала проміжок попередньої моделі
    const intervalForNewModel: WallRangeT = {
      empty: false,
      range: [
        offsetDistance - newModelWidth / 2,
        offsetDistance + newModelWidth / 2,
      ],
      name: nextPointName,
    };
    intervalsInfoOnWall = addIntervalToArrIntervals(
      intervalsInfoOnWall,
      intervalForNewModel
    );
  }
};
