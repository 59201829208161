import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { ModelsName_NodesT, WallItemT } from "../../../../utils/constants/nodesNamesThreekit";
import { getTranslationThreekit } from "../../../../utils/threekit/general/getFunctions";
import { setTranslationThreekit } from "../../../../utils/threekit/general/setFunctions";
import { moveCoordsByVector } from "../../../wallsAndFloor/buildWallFromData";
import { getLeftRigthDirectionsWall } from "../../../wallsAndFloor/getGeneralWallInfo";
import { getModelsBaseNullOnWall } from "../../getNodesCabinets";
import { getNullNameMovedModelForCornerEmpty } from "../../../../utils/threekit/tools/toolsDragCabinetsBase/generalFunc";
import { ArrWallRangesT } from "../../../intervals/getIntervalsOnWallForCabinetsWall";

export const moveCabinetBaseOnWall = (
  nullNameCabinetBase: ModelsName_NodesT,
  moveDirectionVector: ICoordinates,
  moveDistance: number,
) => {
  const currentTranslation = getTranslationThreekit({
    name: nullNameCabinetBase
  })
  const newTranslation = moveCoordsByVector(
    currentTranslation,
    moveDirectionVector,
    moveDistance
  );
  setTranslationThreekit({
    name: nullNameCabinetBase,
    value: newTranslation,
  })
}

export type MoveDirectionOnWallT =  "left" | "right"
export const moveAllCabinetsBaseOnWall = (wallName: WallItemT, moveDirection: MoveDirectionOnWallT, moveDistance: number) => {
  const cabinetsBaseOnWall = getModelsBaseNullOnWall(wallName);
  const { leftDirectionWall, rightDirectionWall } = getLeftRigthDirectionsWall(wallName);

  const moveDirectionOnWall = moveDirection === "left" ? leftDirectionWall : rightDirectionWall;

  cabinetsBaseOnWall.forEach((cabinetBaseNullName) => {

    moveCabinetBaseOnWall(
      cabinetBaseNullName,
      moveDirectionOnWall,
      moveDistance
    );

  })

}

export const moveCabinetBaseOnWallForEmptyCorner = (
  wallName: WallItemT,
  wallIntervals: ArrWallRangesT,
  moveDirection: MoveDirectionOnWallT,
  moveDistance: number
) => {
  const nullNameMovedModel =  getNullNameMovedModelForCornerEmpty(
    wallIntervals,
    moveDirection,
  );

  if (nullNameMovedModel === "") return;

  const { leftDirectionWall, rightDirectionWall } = getLeftRigthDirectionsWall(wallName);
  const moveDirectionOnWall = moveDirection === "left" ? leftDirectionWall : rightDirectionWall;
  moveCabinetBaseOnWall(
    nullNameMovedModel,
    moveDirectionOnWall,
    moveDistance
  )
}