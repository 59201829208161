import React, { useEffect, useState } from "react";
//@ts-ignore
import s from "./BaseSelectColor.module.scss";

import { BaseSelectColorT, getIconT } from "./BaseSelectColorT";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { ArrowBottomSelectColorSvg } from "../../../assets/images/svg/ArrowBottomSelectColor";
import { activeOptionT } from "../../../store/slices/settingsUI/typesSettingsUISlice";

export const BaseSelectColor = ({ ...props }) => {
  const { values, onChange, value, errorComponent, disabled = false } = props;

  let selectValue = values.find((option: activeOptionT) => option["value"] === value);

  let classInput = `${s.selectColor}`;

  const colorStyle = (color = "transparent") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 0,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 48,
      width: 48,
    },
  });

  const customStyles: any = {
    control: (provided: any, state: any) => {
      let menuIsOpen = state["menuIsOpen"];

      return {
        ...provided,

        border: "1px solid rgba(0, 0, 0, 0.125)",
        boxSizing: "border-box",
        borderRadius: "2px",
        borderBottomLeftRadius: menuIsOpen ? "0" : "2px",
        borderBottomRightRadius: menuIsOpen ? "0" : "2px",
        boxShadow: "none",
        borderColor: errorComponent && "#DE5555 !important",
        padding: "6px 23px 6px 6px",
        minHeight: "auto",
        cursor: "pointer",
        backgroundColor: "#fff",
        "&:hover": {
          borderColor: "rgba(0, 0, 0, 0.125)",
        },
      };
    },
    singleValue: (provided: any, state: any) => {
      const valueSelect = state.getValue();
      const activeValueColor = valueSelect[0]["color"];
      return {
        ...provided,
        ...colorStyle(activeValueColor),

        fontFamily: "OpenSans",
        fontSize: "14px",
        lineHeight: "20px",
        color: "rgba(0, 0, 0, 0.85)",
        margin: "0",
      };
    },
    valueContainer: (provided: any, state: any) => {
      return {
        ...provided,

        padding: "0",
      };
    },
    dropdownIndicator: (provided: any, state: any) => {
      return {
        ...provided,

        padding: "0",
        color: "hsl(0, 0%, 40%)",
        opacity: state["isDisabled"] ? 0.15 : 1,
      };
    },
    option: (provided: any, state: any) => {
      const { isSelected, data, isDisabled } = state;
      provided = {
        ...provided,
        ...colorStyle(data["color"]),

        fontFamily: "OpenSans",
        lineHeight: "20px",
        padding: "16px 16px 16px 24px",
        backgroundColor: isSelected ? "#3C93E4" : "#fff",
        color: isSelected ? "#ffffff" : "rgba(0, 0, 0, 0.85) !important",
        // pointerEvents: isSelected ? 'none' : 'auto',
        transition: "0.3s",
        fontSize: "14px",
        fontWeight: isSelected ? 600 : 400,
        cursor: isSelected ? "default" : "pointer",
        "&:hover": {
          background: isSelected ? "#3C93E4" : " #f5f5f5 !important",
          // cursor: isSelected ? "default" : "pointer",
        },
      };
      if (isDisabled) {
        provided = {
          ...provided,
          opacity: 0.2,
          "-webkit-user-select": "none",
          "-moz-user-select": "none",
          "-ms-user-select": "none",
          "user-select": "none",
          cursor: "default",
        }
      }
      return { ...provided };
    },
    menu: (provided: any, state: any) => {
      return {
        ...provided,

        marginBottom: "0px",
        marginTop: "0px",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
        borderTop: "none",
        border: "1px solid rgba(0, 0, 0, 0.125)",
        boxShadow: "none",
      };
    },
    menuList: (provided: any, state: any) => {
      return {
        ...provided,

        paddingBottom: "0",
        paddingTop: "0",
      };
    },
  };

  const DropdownIndicator = (
    props: DropdownIndicatorProps
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowBottomSelectColorSvg />
      </components.DropdownIndicator>
    );
  };

  const [selectValueCurrent, setSelectValueCurrent] = useState(selectValue);

  useEffect(() => {
    let selectValue = values.find((option: activeOptionT) => option["value"] === value);
    setSelectValueCurrent(selectValue);
  }, [value]);

  return (
    <Select
      className={classInput}
      placeholder="Select value"
      onChange={(prop: any) => {
        setSelectValueCurrent(prop);
        onChange(prop["value"]);
      }}
      value={selectValueCurrent}
      // menuIsOpen={true}
      options={values}
      styles={customStyles}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      isSearchable={false}
      isDisabled={disabled}
    />
  );
};
