import { PlaneCabinetsWallT } from "../../utils/constants/nodesNamesThreekit";
import { getBoxWidthThreekit } from "../../utils/threekit/general/getFunctions";
import { getIntervalSize } from "./generalIntervals";
import { ArrWallRangesT, WallRangeT, addIntervalToArrIntervals, getIntervalsCabinetWallFeaturesFridge } from "./getIntervalsOnWallForCabinetsWall";
import { getSizeNewInterval } from "./intervalsInfoForPositionedNewCabinetsWall";

/**
 * Визначає чи присутній на сусідній стіні зліва в спільному куті Cabinet Wall або інша модель.
 * Та, якщо модель в куті присутня, додаємо відповідний інтервал в загальний масив інтервалів.
 *
 * @param {ArrWallRangesT} baseIntervals Загальний масив інтервалів.
 * @param {PlaneCabinetsWallT | undefined} planeLeftName Ім'я сусіднього зліва плейну.
 * @param {number} offsetCorner Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах.
 * @return {ArrWallRangesT} Змінений або той самий масив інтервалів baseIntervals.
 */
export const checkLeftIntervalCabinetsWall = (
  baseIntervals: ArrWallRangesT,
  planeLeftName: PlaneCabinetsWallT | undefined,
  offsetCorner: number
): ArrWallRangesT => {
  // Якщо не знайдений сусідній зліва плейн, повертаємо той самий масив інтервалів
  if (planeLeftName === undefined) return baseIntervals;

  // Отримуємо масив інтервалів для сусіднього зліва плейну
  // Цей масив інтервалів має враховувати Cabinets Wall, Холодильник, Вікна, Двері, Проеми
  const intervalsOnLeftWall =
    getIntervalsCabinetWallFeaturesFridge(planeLeftName);

  // Шукаємо останній інтервал для данного плейну
  const lastIntervalOnLeftWall =
    intervalsOnLeftWall[intervalsOnLeftWall.length - 1];

  // Перевіряємо:
  // - якщо останній інтервал пустий І має розмір більший ніж розмір глубини моделі
  // то повертаємо той самий масив інтервалів
  if (
    lastIntervalOnLeftWall["empty"] &&
    getIntervalSize(lastIntervalOnLeftWall) > offsetCorner
  )
    return baseIntervals;

  // В іншому випадку додаємо в загальний масив інтервалів на стіні ще один заповнений інтервал на початок
  // який визначатиме що на сусідній стіні зліва близько до кута розташований шкаф
  // та матиме розмір, який відповідає розміру глубини моделі, яка знаходиться на сусідній стіні близько до кута

  // Шукаємо ім'я моделі, що знаходиться близько до кута на сусідній зліва стіні
  const reversedIntervalsOnLeftWall = [...intervalsOnLeftWall].reverse();
  const indexLastFilledInterval = reversedIntervalsOnLeftWall.findIndex(
    (objInterval) => objInterval["name"] !== undefined
  );
  const nameLeftModel =
    reversedIntervalsOnLeftWall[indexLastFilledInterval]["name"];

  // Знаходимо розмір нового проміжку
  const sizeNewInterval = getSizeNewInterval(nameLeftModel);

  if (sizeNewInterval === 0) return baseIntervals;

  // Створюємо новий інтервал зліва
  const newLeftInterval: WallRangeT = {
    empty: false,
    name: nameLeftModel,
    range: [0, sizeNewInterval],
  };

  // Додаємо новий інтервал в загальний масив інтервалів на початок і повертаємо його
  return addIntervalToArrIntervals(baseIntervals, newLeftInterval);
};

/**
 * Визначає чи присутній на сусідній стіні зправа в спільному куті Cabinet Wall або інша модель.
 * Та, якщо модель в куті присутня, додаємо відповідний інтервал в загальний масив інтервалів.
 *
 * @param {ArrWallRangesT} baseIntervals Загальний масив інтервалів.
 * @param {PlaneCabinetsWallT | undefined} planeRightName Ім'я сусіднього зправа плейну.
 * @param {number} offsetCorner Мінімальна відстань від кута, на якій мають розташовуватись шкафи на сусідніх стінах.
 * @return {ArrWallRangesT} Змінений або той самий масив інтервалів baseIntervals.
 */
export const checkRightIntervalCabinetsWall = (
  baseIntervals: ArrWallRangesT,
  currentPlaneName: PlaneCabinetsWallT,
  planeRightName: PlaneCabinetsWallT | undefined,
  offsetCorner: number
): ArrWallRangesT => {
  // Якщо не знайдений сусідній зправа плейн, повертаємо той самий масив інтервалів
  if (planeRightName === undefined) return baseIntervals;

  // Отримуємо масив інтервалів для сусіднього зправа плейну
  // Цей масив інтервалів має враховувати Cabinets Wall, Холодильник, Вікна, Двері, Проеми
  const intervalsOnRightWall =
    getIntervalsCabinetWallFeaturesFridge(planeRightName);

  // Шукаємо перший інтервал для данного плейну
  const firstIntervalOnLeftWall = intervalsOnRightWall[0];

  // Перевіряємо:
  // - якщо перший інтервал пустий І має розмір більший ніж розмір глубини моделі
  // то повертаємо той самий масив інтервалів
  if (
    firstIntervalOnLeftWall["empty"] &&
    getIntervalSize(firstIntervalOnLeftWall) > offsetCorner
  )
    return baseIntervals;

  // В іншому випадку додаємо в загальний масив інтервалів на стіні ще один заповнений інтервал в кінець
  // який визначатиме що на сусідній стіні зправа близько до кута розташована модель
  // та матиме розмір, який відповідає розміру глубини моделі, яка знаходиться на сусідній стіні близько до кута

  // Шукаємо ім'я моделі, що знаходиться близько до кута на сусідній зправа стіні
  const indexFirstFilledInterval = intervalsOnRightWall.findIndex(
    (objInterval) => objInterval["name"] !== undefined
  );
  const nameRightModel = intervalsOnRightWall[indexFirstFilledInterval]["name"];

  // Знаходимо розмір нового проміжку
  const sizeNewInterval = getSizeNewInterval(nameRightModel);

  if (sizeNewInterval === 0) return baseIntervals;

  // Отримуємо розмір плейну, для формування нового інтервалу
  const currentPlaneWidth = getBoxWidthThreekit({ name: currentPlaneName });

  // Створюємо новий інтервал зправа
  const newLeftInterval: WallRangeT = {
    empty: false,
    name: nameRightModel,
    range: [currentPlaneWidth - sizeNewInterval, currentPlaneWidth],
  };

  // Додаємо новий інтервал в загальний масив інтервалів на початок і повертаємо його
  return addIntervalToArrIntervals(baseIntervals, newLeftInterval);
};