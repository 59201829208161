import { ModelsName_NodesT } from "../../utils/constants/nodesNamesThreekit";
import { ArrWallRangesT, WallRangeT } from "./getIntervalsOnWallForCabinetsWall";

export interface NeaborIntervalsI {
  modelRange: WallRangeT;
  leftNeabor: WallRangeT | undefined;
  rightNeabor: WallRangeT | undefined;
}
/**
 * Знаходить інтервал для моделі та повертає об'єкт,
 * в якому є інформація про інтервал моделі та про сусідні інтервали зліва та справа
 *
 * @param {ArrWallRangesT} arrIntervals Масив інтервалів, по якому потрібно шукати інтервал моделі
 * @param {ModelsName_NodesT[]} nullNameCabinetWall Null Name моделі.
 * @return {NeaborIntervalsCabinetWallI} об'єкт, в якому є інформація про інтервал моделі та про сусідні інтервали зліва та справа
 */
export const getNeaborIntervals = (
  arrIntervals: ArrWallRangesT,
  nullNameCabinetWall: ModelsName_NodesT
): NeaborIntervalsI | null => {
  let resultObj: NeaborIntervalsI | null = null;
  let prevInterval: WallRangeT | undefined = undefined;
  arrIntervals.forEach((objInterval, index) => {
    const {empty, range, name} = objInterval;
    if (!empty && name !== undefined && name === nullNameCabinetWall) {
      const nextInterval = arrIntervals[index + 1];
      if (resultObj === null) {
        resultObj = {
          modelRange: objInterval,
          leftNeabor: prevInterval,
          rightNeabor: nextInterval,
        }
      }
    }
    prevInterval = objInterval;
  })
  return resultObj;
}