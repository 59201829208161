export enum CONNECTORS_CABINETS_BASE {
  LEFT = "connector_left",
  RIGHT = "connector_right",

  BOTTOM_BACK_REGEXP = "connector_bottom_back_*",
  BOTTOM_BACK_12 = "connector_bottom_back_12",
}

export enum CONNECTORS_CABINET_WALL {

  TOP_LEFT = "connector_wall_top_left", // connect to --> "connector_wall_top_right", "connector_wall_bottom_right", "connector_wall_bottom_left_inner"
  TOP_RIGHT = "connector_wall_top_right", // connect to --> "connector_wall_top_left", "connector_wall_bottom_left", "connector_wall_bottom_right_inner"
  TOP_LEFT_INNER = "connector_wall_top_left_inner", // connect to --> "connector_wall_bottom_left"
  TOP_RIGHT_INNER = "connector_wall_top_right_inner", // connect to --> "connector_wall_bottom_right"

  BOTTOM_LEFT = "connector_wall_bottom_left", // connect to --> "connector_wall_bottom_right", "connector_wall_top_right", "connector_wall_top_left_inner"
  BOTTOM_RIGHT = "connector_wall_bottom_right", // connect to --> "connector_wall_bottom_left", "connector_wall_top_left", "connector_wall_top_right_inner"
  BOTTOM_LEFT_INNER = "connector_wall_bottom_left_inner", // connect to --> "connector_wall_top_left"
  BOTTOM_RIGHT_INNER = "connector_wall_bottom_right_inner", // connect to --> "connector_wall_top_right"

  CONNECTION_BASE = "connector_top_back_12", // для з'єднання з напольними шкафами по вертикалі по відповідному розміру X
  CONNECTION_BASE_REGEXP = "connector_top_back_*", // для з'єднання з напольними шкафами по вертикалі по regexp

  BOTTOM_UPPER_PANTRY_REGEXP = "connector_bottom_*", // для з'єднання з напольними шкафами по вертикалі по regexp

}

export enum CONNECTORS_CABINET_ISLAND {
  LEFT = "connector_island_left",
  RIGHT = "connector_island_right",
  BACK_LEFT = "connector_island_back_left",
  BACK_RIGHT = "connector_island_back_right",
  LEFT_FRONT = "connector_island_left_front",
  RIGHT_FRONT = "connector_island_right_front",
}