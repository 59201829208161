import {
  CONNECTORS_CABINETS_BASE,
  CONNECTORS_CABINET_WALL,
} from "../../../utils/constants/connectors";
import {
  ModelsName_NodesT,
  NODES_THREEKIT,
  PlaneCabinetsWallT,
  WallItemT,
} from "../../../utils/constants/nodesNamesThreekit";
import { getKeys } from "../../../utils/other/getObjKeysFromType";
import {
  getInstanceIdAssetFromNullModel,
  getNameNodeThreekit,
} from "../../../utils/threekit/general/getFunctions";
import { setActiveTagsThreekit } from "../../../utils/threekit/general/setFunctions";
import { isFeaturesModelNullName } from "../../features/general";
import { getNumberNodeThreekitFromName } from "../../general";
import { checkIntersectIntervalFromIntervals } from "../../intervals/intersectIntervals";
import {
  AllWallsIntervalsWallT,
  getIntervalsWallCabinetsForAllWalls,
} from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { getIntervalsBaseCabinetsForAllWalls } from "../../intervals/getIntervalsInfoOnWallBase";

export const updateConnectorsVerticalCabinetsWall = (
  instanceIdConnectorsParentAsset: string,
  value: boolean
) => {
  setActiveTagsThreekit({
    from: instanceIdConnectorsParentAsset,
    name: CONNECTORS_CABINET_WALL.CONNECTION_BASE_REGEXP,
    value: value,
  });
};

export const updateConnectorsVerticalCabinetsBase = (
  instanceIdConnectorsParentAsset: string,
  value: boolean
) => {
  setActiveTagsThreekit({
    from: instanceIdConnectorsParentAsset,
    name: CONNECTORS_CABINETS_BASE.BOTTOM_BACK_REGEXP,
    value: value,
  });
};

const getFilteredIntervals = (
  nullNameSelectedCabinetWall: string,
  wallName: WallItemT,
  intervalsCabinetsWallForAllWalls: AllWallsIntervalsWallT
) => {
  const namePlaneCabinetsWall = `${
    NODES_THREEKIT.PLANE_CABINETS_WALL
  }${getNumberNodeThreekitFromName(wallName)}` as PlaneCabinetsWallT;
  const arrWallIntervalsCabinetsWall =
    intervalsCabinetsWallForAllWalls[namePlaneCabinetsWall];
  const filteredIntervalsCabinetsWallNotSelected =
    arrWallIntervalsCabinetsWall.filter((objIntervalCabinetsWall) => {
      return objIntervalCabinetsWall["name"] !== nullNameSelectedCabinetWall;
    });
  return filteredIntervalsCabinetsWallNotSelected;
};

export const checkCabinetsBaseVerticalConnectors = (
  idSelectedCabinetWall: string
) => {

  const nullNameSelectedCabinetWall = getNameNodeThreekit({
    id: idSelectedCabinetWall,
  });

  const instanceIdAssetCabinetWall = getInstanceIdAssetFromNullModel({
    name: nullNameSelectedCabinetWall,
  });
  updateConnectorsVerticalCabinetsWall(instanceIdAssetCabinetWall, true);

  const intervalsCabinetsBaseForAllWalls =
    getIntervalsBaseCabinetsForAllWalls();
  const intervalsCabinetsWallForAllWalls =
    getIntervalsWallCabinetsForAllWalls();

  const keysIntervalsCabinetsBaseForAllWalls = getKeys(
    intervalsCabinetsBaseForAllWalls
  );

  keysIntervalsCabinetsBaseForAllWalls.forEach((wallName) => {
    const arrWallIntervalsCabinetsBase =
      intervalsCabinetsBaseForAllWalls[wallName];

    const filteredIntervalsCabinetsWallNotSelected = getFilteredIntervals(
      nullNameSelectedCabinetWall,
      wallName,
      intervalsCabinetsWallForAllWalls
    );

    arrWallIntervalsCabinetsBase.forEach((interval, indx) => {
      let { empty, range, name } = interval;

      if (!empty && name !== undefined && !isFeaturesModelNullName(name)) {
        const nullNameCabinetBase = name as ModelsName_NodesT;
        const rangeCabinetBase = range;

        const isIntersectsIntervals = checkIntersectIntervalFromIntervals(
          rangeCabinetBase,
          filteredIntervalsCabinetsWallNotSelected
        );

        const instanceIdAssetCabinetBase = getInstanceIdAssetFromNullModel({
          name: nullNameCabinetBase,
        });

        if (isIntersectsIntervals) {
          updateConnectorsVerticalCabinetsBase(
            instanceIdAssetCabinetBase,
            false
          );
        } else {
          updateConnectorsVerticalCabinetsBase(
            instanceIdAssetCabinetBase,
            true
          );
        }
      }
    });
  });
};
