import { Dispatch } from "@reduxjs/toolkit";
import { ModelCabinetWallT } from "../../../utils/constants/nodesNamesThreekit";
import {
  getRotationThreekit,
  getTranslationThreekit,
} from "../../../utils/threekit/general/getFunctions";
import { setTranslationThreekit } from "../../../utils/threekit/general/setFunctions";
import { CABINETS_WALL_STANDART_HEIGHT } from "../constatns";
import {
  getEvalNodesCornerEmptyCabinetsWall,
  removeCornerEmpty,
} from "./checkCornersEmptyRemove";
import { getDistanceFromCornerEmtyWallBoxToCabinetsWall } from "./distanceBox";
import { getSizeModelBoxFromAssetCabinetWall } from "./size";
import { getConfiguratorModelFromNullName } from "../configuration/decorativePanel";

enum ATTRIBUTES_SCALE_CORNER_EMPTY_CABINET_WALL {
  SCALE_CORNER_EMPTY_CABINET_WALL = "scaleCornerEmptyCabinetWall",
}

export const checkScaleOrRemoveCornerEmpty = (dispatch: Dispatch) => {
  const arrEvalNodesCornerEmptyCabinetsWall =
    getEvalNodesCornerEmptyCabinetsWall();

  arrEvalNodesCornerEmptyCabinetsWall.forEach((evalNodeCornerEmpty) => {
    const nullNameCornerEmpty = evalNodeCornerEmpty[
      "name"
    ] as ModelCabinetWallT;
    const rotationCornerEmpty = getRotationThreekit({
      name: nullNameCornerEmpty,
    });
    const arrModelsNamesWithShortDistances =
      getDistanceFromCornerEmtyWallBoxToCabinetsWall(
        evalNodeCornerEmpty["name"] as ModelCabinetWallT
      );

    let objPositions: {
      left: {
        top: number[];
        bottom: number[];
      };
      right: {
        top: number[];
        bottom: number[];
      };
    } = {
      left: {
        top: [],
        bottom: [],
      },
      right: {
        top: [],
        bottom: [],
      },
    };

    arrModelsNamesWithShortDistances.forEach((nullName) => {
      // console.log('nullName --- ==== ',nullName);
      const positionNeaborModel = getTranslationThreekit({ name: nullName });
      const rotationNeaborModel = getRotationThreekit({ name: nullName });
      const sizeNeaborModel = getSizeModelBoxFromAssetCabinetWall(nullName);
      // console.log('positionNeaborModel --- ==== ',positionNeaborModel);
      // console.log('rotationNeaborModel --- ==== ',rotationNeaborModel);

      // console.log('rotation diff --- ==== ',rotationCornerEmpty["y"] - rotationNeaborModel["y"]);

      if (
        Math.abs(rotationCornerEmpty["y"] - rotationNeaborModel["y"]) < 0.05
      ) {
        objPositions["left"]["top"].push(
          positionNeaborModel["y"] + sizeNeaborModel["y"]
        );
        objPositions["left"]["bottom"].push(positionNeaborModel["y"]);
      } else if (
        Math.abs(rotationCornerEmpty["y"] - rotationNeaborModel["y"]) >=
          90 - 0.05 &&
        Math.abs(rotationCornerEmpty["y"] - rotationNeaborModel["y"]) <=
          90 + 0.05
      ) {
        objPositions["right"]["top"].push(
          positionNeaborModel["y"] + sizeNeaborModel["y"]
        );
        objPositions["right"]["bottom"].push(positionNeaborModel["y"]);
      }
    });

    if (
      objPositions["left"]["top"].length < 1 ||
      objPositions["left"]["bottom"].length < 1 ||
      objPositions["right"]["top"].length < 1 ||
      objPositions["right"]["bottom"].length < 1
    ) {
      return removeCornerEmpty(dispatch, nullNameCornerEmpty);
    }

    const maxLeftTop = Math.max(...objPositions["left"]["top"]);
    const minLeftBottom = Math.min(...objPositions["left"]["bottom"]);

    const maxRightTop = Math.max(...objPositions["right"]["top"]);
    const minRightBottom = Math.min(...objPositions["right"]["bottom"]);

    const minTop = Math.min(maxLeftTop, maxRightTop);
    const maxBottom = Math.max(minLeftBottom, minRightBottom);

    const heightCornerEmptyCalculated = minTop - maxBottom;
    const diffHeight = Number(
      (heightCornerEmptyCalculated - CABINETS_WALL_STANDART_HEIGHT).toFixed(2)
    );

    const scale = diffHeight / CABINETS_WALL_STANDART_HEIGHT;

    // if (scale === 0) {
    //   return setScaleThreekit({
    //     from: instanseIdCurrentCornerEmpty,
    //     name: "WrapCornerEmpty",
    //     value: { ...scaleCurrentCornerEmpty, y: 1 }
    //   })
    // };

    const configuratorCurrentCornerEmpty =
      getConfiguratorModelFromNullName(nullNameCornerEmpty);

    configuratorCurrentCornerEmpty.setConfiguration({
      [ATTRIBUTES_SCALE_CORNER_EMPTY_CABINET_WALL.SCALE_CORNER_EMPTY_CABINET_WALL]:
        1 + scale,
    });

    // setScaleThreekit({
    //   from: instanseIdCurrentCornerEmpty,
    //   name: "WrapCornerEmpty",
    //   value: { ...scaleCurrentCornerEmpty, y: 1 + scale }
    // })

    const positionCurrentCornerEmpty = getTranslationThreekit({
      name: nullNameCornerEmpty,
    });
    setTranslationThreekit({
      name: nullNameCornerEmpty,
      value: { ...positionCurrentCornerEmpty, y: maxBottom },
    });
  });
};
