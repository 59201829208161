import { useAppDispatch, useAppSelector } from "../../../hooks/useStoreHooks";
import { getModalInfo } from "../../../store/selectors/modalsSelectors";
import { setModalVisible } from "../../../store/slices/modalsSlice/modalsSlice";
import { MODAL_KEYS } from "../../../store/slices/modalsSlice/modalsSliceT";
import { ButtonMain } from "../../StandartComponents/Buttons/ButtonMain";
import { ModalsWrap } from "../ModalsWrap/ModalsWrap";
import s from "./ModalMessage.module.scss";

export const ModalMessage = ({ ...props }) => {
  const modalInfo = useAppSelector((state) => getModalInfo(state)(MODAL_KEYS.MESSAGE));
  const dispatch = useAppDispatch();

  const handleContinue = () => {
    dispatch(setModalVisible({ modalId: MODAL_KEYS.MESSAGE, value: false}))
  }

  if (!modalInfo["isVisible"]) return null;

  return (
    <ModalsWrap modalKey={MODAL_KEYS.MESSAGE} zIndex={1005} >
      <div className={s.modalMessage}>
        {!!modalInfo["message"] && <p className={s.title}>{modalInfo["message"]}</p>}
        <div className={s.btnsWrap}>
          <div className={s.col}>
            <ButtonMain text="Continue" onClick={handleContinue} color="blueDark" size="bigSuper" fullWidth={true} bold={true} />
          </div>
        </div>
      </div>
    </ModalsWrap>
  )
}