import { CONNECTORS_CABINET_ISLAND, CONNECTORS_CABINET_WALL } from "../../utils/constants/connectors";
import { ModelsName_NodesT } from "../../utils/constants/nodesNamesThreekit";
import { getIdNodeThreekit, getInstanceIdAssetFromNullModel } from "../../utils/threekit/general/getFunctions";

export const getConnectorIdForCabinet = (
  nullName: ModelsName_NodesT,
  connectorName: CONNECTORS_CABINET_ISLAND | CONNECTORS_CABINET_WALL
) => {
  const instanceId = getInstanceIdAssetFromNullModel({
    name: nullName
  });
  const nodeIdConnector = getIdNodeThreekit({
    from: instanceId,
    name: connectorName
  })
  return nodeIdConnector;
}

export const getConnectorConnected = (
  nullName: ModelsName_NodesT,
  connectorName: CONNECTORS_CABINET_ISLAND | CONNECTORS_CABINET_WALL
): string | null => {
  const connectorId = getConnectorIdForCabinet(nullName, connectorName);
  //@ts-ignore
  return window.threekit.player.connectors.getConnected(connectorId)
}