import { listActiveSettingT } from "../../../store/slices/settingsUI/typesSettingsUISlice";
import { ATTRIBUTES_NAMES_THREEKIT } from "../../../utils/constants/attributesThreekit";
import { COLORS_VALUES } from "../../../utils/constants/colorsRoom";
// import { FRAMED_OPTIONS_VALUES } from "../../../utils/constants/framedOptions";
import { getFullConfigurationAttribute } from "../../../utils/threekit/general/getFunctions";
import { setConfigurationFull } from "../../../utils/threekit/general/setFunctions";

// export const getConfigurationForDoorStyle = (value: string) => {
//   switch (value) {
//     case FRAMED_OPTIONS_VALUES.FRAMED:
//       return { "Door Style": "Framed" };
//     case FRAMED_OPTIONS_VALUES.FRAMELESS:
//       return { "Door Style": "Frameless" };
//     default:
//       return {};
//   }
// };

// const updateFullConfigurationForDoorStyle = (
//   value: string,
//   oldConfiguration: any
// ) => {
//   switch (value) {
//     case FRAMED_OPTIONS_VALUES.FRAMED:
//       return oldConfiguration.map((model: any) => ({
//         ...model,
//         configuration: {
//           ...model.configuration,
//           "Door Style": "Framed"
//         },
//       }));
//     case FRAMED_OPTIONS_VALUES.FRAMELESS:
//       return oldConfiguration.map((model: any) => ({
//         ...model,
//         configuration: {
//           ...model.configuration,
//           "Door Style": "Frameless",
//         },
//       }));

//     default:
//       return oldConfiguration;
//   }
// };

export const getConfigurationForCabinetMaterial = (value: string) => {
  switch (value) {
    case COLORS_VALUES.WHITE:
      return { "Cabinet material": "White Shaker" };
    case COLORS_VALUES.GREY:
      return { "Cabinet material": "Grey Shaker", };
    default:
      return {};
  }
};

const updateFullConfigurationForCabinetMaterial = (
  value: string,
  oldConfiguration: any
) => {
  switch (value) {
    case COLORS_VALUES.WHITE:
      return oldConfiguration.map((model: any) => ({
        ...model,
        configuration: {
          ...model.configuration,
          "Cabinet material": "White Shaker",
        },
      }));
    case COLORS_VALUES.GREY:
      return oldConfiguration.map((model: any) => ({
        ...model,
        configuration: {
          ...model.configuration,
          "Cabinet material": "Grey Shaker",
        },
      }));
    default:
      return oldConfiguration;
  }
};

export const setConfigurationForOneCabinetType = (
  id: string,
  value: string
) => {

  if (id.includes("BaseCabinets")) {
    let fullConfiguration = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE);

    if (id.includes("Color")) {
      fullConfiguration = updateFullConfigurationForCabinetMaterial(
        value,
        fullConfiguration
      );
    }/* else {
      fullConfiguration = updateFullConfigurationForDoorStyle(
        value,
        fullConfiguration
      );
    }*/

    setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE, fullConfiguration);
  }

  if (id.includes("WallCabinets")) {
    let fullConfiguration = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL);

    if (id.includes("Color")) {
      fullConfiguration = updateFullConfigurationForCabinetMaterial(
        value,
        fullConfiguration
      );
    }/* else {
      fullConfiguration = updateFullConfigurationForDoorStyle(
        value,
        fullConfiguration
      );
    }*/

    setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL, fullConfiguration);
  }

  if (id.includes("IslandCabinets")) {
    let fullConfiguration = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND);

    if (id.includes("Color")) {
      fullConfiguration = updateFullConfigurationForCabinetMaterial(
        value,
        fullConfiguration
      );
    }/* else {
      fullConfiguration = updateFullConfigurationForDoorStyle(
        value,
        fullConfiguration
      );
    }*/

    setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND, fullConfiguration);
  }

};

export const setDoorStyleForAllCabinetsType = (value: string, id: string) => {
  let cabinetBase = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE);
  let cabinetsWall = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL);
  let cabinetsIsland = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND);

  if (id.includes("Color")) {
    cabinetBase = updateFullConfigurationForCabinetMaterial(value, cabinetBase);
    cabinetsWall = updateFullConfigurationForCabinetMaterial(value, cabinetsWall);
    cabinetsIsland = updateFullConfigurationForCabinetMaterial(value, cabinetsIsland);
  }/* else {
    cabinetBase = updateFullConfigurationForDoorStyle(value, cabinetBase);
    cabinetsWall = updateFullConfigurationForDoorStyle(value, cabinetsWall);
    cabinetsIsland = updateFullConfigurationForDoorStyle(value, cabinetsIsland);
  }*/
  setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE, cabinetBase);
  setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL, cabinetsWall);
  setConfigurationFull(ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND, cabinetsIsland);
};

export const setDoorStyleAndColorCabinetFromStateToThreekit = (
  activeSetting: listActiveSettingT[]
) => {
  let cabinetBase = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_BASE);
  let cabinetsWall = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_WALL);
  let cabinetsIsland = getFullConfigurationAttribute(ATTRIBUTES_NAMES_THREEKIT.CABINETS_ISLAND);

  const cabinetBuild = activeSetting.find(
    (setting) => setting.sectionId === "CabinetBuild"
  );
  const cabinetColor = activeSetting.find(
    (setting) => setting.sectionId === "CabinetColor"
  );

  if (cabinetBuild) {
    const listOptionsObj = cabinetBuild["listOptionsObj"];
    Object.keys(listOptionsObj).forEach((key: any) => {
      if (key.includes("AllCabinets_Frame")) {
        const value: any = listOptionsObj[key].value;
        setDoorStyleForAllCabinetsType(value, "");
      }
    });
    // if (cabinetBase.length >= 1) {
    //   const listOptionsObj = cabinetBuild["listOptionsObj"];

    //   Object.keys(listOptionsObj).forEach((key: any) => {
    //     if (key.includes("BaseCabinets_Frame")) {
    //       const value: any = listOptionsObj[key].value;
    //       setDoorStyleForAllCabinetsType(value, "");
    //     }
    //   });
    // }
    // if (cabinetsWall.length >= 1) {
    //   const listOptionsObj = cabinetBuild["listOptionsObj"];

    //   Object.keys(listOptionsObj).forEach((key: any) => {
    //     if (key.includes("WallCabinets_Frame")) {
    //       const value: any = listOptionsObj[key].value;
    //       setDoorStyleForAllCabinetsType(value, "");
    //     }
    //   });
    // }
    // if (cabinetsIsland.length >= 1) {
    //   const listOptionsObj = cabinetBuild["listOptionsObj"];

    //   Object.keys(listOptionsObj).forEach((key: any) => {
    //     if (key.includes("IslandCabinets_Frame")) {
    //       const value: any = listOptionsObj[key].value;
    //       setDoorStyleForAllCabinetsType(value, "");
    //     }
    //   });
    // }
  }

  if (cabinetColor) {
    if (cabinetBase.length >= 1) {
      const listOptionsObj = cabinetColor["listOptionsObj"];

      Object.keys(listOptionsObj).forEach((key: any) => {
        if (key.includes("BaseCabinets_Color")) {
          const value: any = listOptionsObj[key].value;
          setConfigurationForOneCabinetType("BaseCabinets_Color", value);
        }
      });
    }
    if (cabinetsWall.length >= 1) {
      const listOptionsObj = cabinetColor["listOptionsObj"];

      Object.keys(listOptionsObj).forEach((key: any) => {
        if (key.includes("WallCabinets_Color")) {
          const value: any = listOptionsObj[key].value;
          setConfigurationForOneCabinetType("WallCabinets_Color", value);
        }
      });
    }
    if (cabinetsIsland.length >= 1) {
      const listOptionsObj = cabinetColor["listOptionsObj"];

      Object.keys(listOptionsObj).forEach((key: any) => {
        if (key.includes("IslandCabinets_Color")) {
          const value: any = listOptionsObj[key].value;
          setConfigurationForOneCabinetType("IslandCabinets_Color", value);
        }
      });
    }
  }
};
export { setConfigurationFull };
