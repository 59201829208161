import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { RangeT } from "./getIntervalsOnWallForCabinetsWall";

export const getVerticalIntervalModel = ({
  positionModel,
  sizeModel
}: {
  positionModel: ICoordinates,
  sizeModel: ICoordinates
}): RangeT => {
  return [
    positionModel["y"],
    positionModel["y"] + sizeModel["y"]
  ]
}