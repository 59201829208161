import {
  ModelCabinetWallT,
  ModelsName_NodesT,
  NODES_THREEKIT,
  WallItemT,
} from "../../../../utils/constants/nodesNamesThreekit";
import {
  getBoxWidthThreekit,
  getNodeIdFromName,
  getRotationThreekit,
  getSceneInstanceId,
} from "../../../../utils/threekit/general/getFunctions";
import { getAllWallsNode, moveCoordsByVector } from "../../../wallsAndFloor/buildWallFromData";
import {
  getLeftRigthDirectionsPlane,
  getNeighborPlanes,
  getPlaneNameFromWallName,
  getStartEndCoordsPlane,
} from "../../../wallsAndFloor/getWallPlanesInfo";
import {
  addIntervalToArrIntervals,
  ArrWallRangesT,
  checkCornerIntervalEmpty,
  getIntervalForModel,
  getIntervalsInfoOnWall,
  WallRangeT,
} from "../../../intervals/getIntervalsOnWallForCabinetsWall";
import { ActiveAndNewValuesThreekitT } from "../../getObjActiveAndNewValuesAttributeThreekit";
import { getSizeModelBoxFromAssetCabinetWall } from "../size";
import {
  setRotationThreekit,
  setTranslationThreekit,
} from "../../../../utils/threekit/general/setFunctions";
import { CABINETS_WALL_BOTTOM_POSITION, CABINETS_WALL_STANDART_HEIGHT } from "../../constatns";
import { updatePositionConnectorBottom } from "../updatePositionConnectorBottom";
import { getСompletedModelsNullNames } from "./../../getNodesCabinets";
import { isNullNameModelCabinetWallT } from "../checkCabinetsWall";
import { getDefaultPositionYCabinetsWall } from "../position";
import { ICoordinates } from "@threekit-tools/treble/dist/types";
import { getWallNameForCabinet } from "../../getWallNameForCabinet";
import { NeaborIntervalsI, getNeaborIntervals } from "../../../intervals/getNeaborIntervals";
import { checkIntervalForModel } from "../../../intervals/generalIntervals";
import { checkWallVisibility, checkWallVisibilityFromCamera, getNextWall } from "../../../wallsAndFloor/getGeneralWallInfo";
import { getNumberNodeThreekitFromName } from "../../../general";
import { getIntervalsForCkeckNewPositionCabinetWall } from "../../../intervals/intervalsInfoForPositionedNewCabinetsWall";
import { setLockTranslationCabinetWall } from "../checkLockTranslationCabinetsWall";

const getPositionForNewModelFromInterval = (
  sizeCabinetBaseNew: ICoordinates,
  objInterval: WallRangeT,
  side: "left" | "right"
): number => {
  if (side === "left") {
    return objInterval["range"][1] - sizeCabinetBaseNew["x"] / 2;
  } else {
    return objInterval["range"][0] + sizeCabinetBaseNew["x"] / 2;
  }
};

/**
 * Перевіряє чи є на стіні вільне місце для додавання нового напольного шкафа
 *
 * @param {WallItemT} wallName Name стіни, для якої робимо перевірку
 * @param {IDisplayAttributeArrayValue[]} valuesAttributeForAdded Масив values атрибуту, які мають додатися на сцену
 * @return {boolean} true - на стіні є місце для додавання моделей, false - на стіні немає місця для додавання моделей
 */
export const checkEmptyIntervalInWallForCabinetWall = (
  wallName: WallItemT,
  sizeModel: ICoordinates,
  nullNameCabinetWallNew: ModelCabinetWallT,
): boolean => {
  const intervalsForCurrentWall = getIntervalsForCkeckNewPositionCabinetWall(getPlaneNameFromWallName(wallName), nullNameCabinetWallNew, sizeModel);
  const intervalForModel = getIntervalForModel(
    intervalsForCurrentWall,
    sizeModel["x"]
  );
  return intervalForModel !== undefined;
};

/**
 * Рекурсивна функція. Шукає стіну, яка є видимою 
 * та на якій є місце для розташування шкафа розміром sizeModel
 * Перевіряє стіну, що прийшла в параметрі,
 * якщо стіна не проходить перевірку, перевіряє наступну стіну
 * Таким чином перевіряє всі стіни
 *
 * @param {WallItemT} wallName Name стіни, для якої відбувається перевірка на видимість та заповненість
 * @param {number} wallCount = 0. Для виходу з рекурсії, коли перевірені всі стіни
 * @param {number} allWallsLength Кількість всіх стін в кімнаті. Для виходу з рекурсії, коли перевірені всі стіни
 * @param {ICoordinates[]} sizeModel Розміри моделі для якої потрібно перевірити наявність місця на стінах.
 * @return {WallItemT | undefined} Name стіни, яка пройшла перевірку,
 * або undefined - якщо в кімнаті жодна стіна не пройшла перевірку на видимість та заповненість іншими шкафами
 */
const getTargetWallNameForAddedOneCabinetWall = (
  wallName: WallItemT,
  wallCount: number = 0,
  allWallsLength: number,
  sizeModel: ICoordinates,
  nullNameCabinetWallNew: ModelCabinetWallT,
): WallItemT | undefined => {

  const currentWallName = wallName;

  if (wallCount >= allWallsLength) {
    return undefined;
  }

  const isVisibleWall = checkWallVisibility(currentWallName);
  const isIntervalForModel = checkEmptyIntervalInWallForCabinetWall(currentWallName, sizeModel, nullNameCabinetWallNew)

  if (isVisibleWall && isIntervalForModel) {
    return currentWallName;
  } else {
    // Функція для пошуку вільної видимої стіни
    const nextWallName = getNextWall(currentWallName);
    return getTargetWallNameForAddedOneCabinetWall(
      nextWallName,
      wallCount + 1,
      allWallsLength,
      sizeModel,
      nullNameCabinetWallNew
    );
  }
};

/**
 * Перевіряє сусідні інтервали для моделі, яка вже знаходиться в правильній позиції на стіні:
 * 1) Чи інтервал пустий
 * 2) Чи розмір інтервалу підходить для додавання нової моделі
 * Повертає відстань від початку плейну до точки, в якій повинен розміститися центр нової моделі
 * (позицію на плейні для встановлення моделі або undefined)
 *
 * @param {NeaborIntervalsCabinetBaseI | null} neaborIntervals Об'єкт, який описує сусідні інтервали для обраної моделі на стіні.
 * @param {ICoordinates} sizeCabinetBaseNew Розмір моделі, яку потрібно зпозиціонувати на стіні.
 * @return {number | undefined} Позиція на проміжку плейну для позиціонування нової моделі поруч з обраною моделлю.
 * Або undefined - якщо поруч з обраною моделлю немає місця
 */
const getPositionOnWallForNewCabinetWall = (
  neaborIntervals: NeaborIntervalsI | null,
  sizeCabinetBaseNew: ICoordinates
): number | undefined => {
  if (neaborIntervals === null) return undefined;

  if (
    checkIntervalForModel(
      neaborIntervals["leftNeabor"],
      sizeCabinetBaseNew["x"]
    )
  ) {
    return getPositionForNewModelFromInterval(
      sizeCabinetBaseNew,
      neaborIntervals["leftNeabor"] as WallRangeT,
      "left"
    );
  }

  if (
    checkIntervalForModel(
      neaborIntervals["rightNeabor"],
      sizeCabinetBaseNew["x"]
    )
  ) {
    return getPositionForNewModelFromInterval(
      sizeCabinetBaseNew,
      neaborIntervals["rightNeabor"] as WallRangeT,
      "right"
    );
  }
};

/**
 * Повертає координати для нової моедлі на стіні
 *
 * @param {WallItemT} wallName Name стіни, на якій знайдено підходящий проміжок для нової моделі.
 * @param {number} offsetDistance Позиція на проміжку плейну для позиціонування нової моделі (відстань від початку плейну до точки, в якій повинен розміститися центр нової моделі).
 * @param {ICoordinates} sizeModel Розміри моделі.
 * @param {ICoordinates} nullNameModel Null Name моделі.
 * @return {ICoordinates} Тривимірні координати, для встановлення нової моделі в цю позицію на стіні.
 */
const getCoordsNewCabinetWallOnWall = (
  wallName: WallItemT,
  offsetDistance: number,
  sizeModel: ICoordinates,
  nullNameModel: ModelCabinetWallT,
): ICoordinates => {
  const currentPlaneName = getPlaneNameFromWallName(wallName);
  const { leftDirectionPlane, rightDirectionPlane } =
    getLeftRigthDirectionsPlane(currentPlaneName);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(currentPlaneName);

  return moveCoordsByVector(
    { ...currentPlaneCoordsLeft, y: getDefaultPositionYCabinetsWall(nullNameModel) },
    rightDirectionPlane,
    offsetDistance
  );
};

/**
 * Шукає та повертає кут повороту для нової моделі.
 * Кут отримується з значення Rotation для плейну, на якому повинен розташовуватися новий шкаф
 *
 * @param {WallItemT} wallName Name стіни, на якій знайдено підходящий проміжок для нової моделі.
 * @return {ICoordinates} Кут повороту для нової моделі (по осі Y).
 */
const getRotationNewCabinetWallOnWall = (wallName: WallItemT): ICoordinates => {
  const currentPlaneName = getPlaneNameFromWallName(wallName);
  return getRotationThreekit({
    name: currentPlaneName,
  });
};

/**
 * Позиціонує та обертає нову модель на стіні, відповідно до переданих в функцію параметрів.
 * Кут отримується з значення Rotation для плейну, на якому повинен розташовуватися новий шкаф
 *
 * @param {ModelCabinetWallT} nullNameCabinetWallNew Null Name нової моделі.
 * @param {ICoordinates} translation Позиція, в яку потрібно встановити нову модель.
 * @param {ICoordinates} rotation Кут повороту (по осі Y) для нової моделі.
 */
const setPositionNewCabinetWall = (
  nullNameCabinetWallNew: ModelCabinetWallT,
  translation: ICoordinates,
  rotation: ICoordinates
) => {
  setTranslationThreekit({
    name: nullNameCabinetWallNew,
    value: translation,
  });
  setRotationThreekit({
    name: nullNameCabinetWallNew,
    value: {
      x: 0,
      y: rotation["y"],
      z: 0,
    },
  });
  // оновлюємо позицію коннектора для вертикального з'єднання з напольними шкафами
  updatePositionConnectorBottom(getNodeIdFromName(nullNameCabinetWallNew));
};

/**
 * Дозволяє або забороняє переміщення шафи, в залежності від того, на видиму чи приховану стіну додається нова шафа.
 *
 * @param {WallItemT} wallName Name стіни, на якій знайдено підходящий проміжок для нової моделі.
 * @param {ModelCabinetWallT} nullNameCabinetWallNew Null Name нової моделі.
 */
const checkMovedNewCabinetWall = (
  wallName: WallItemT,
  nullNameCabinetWallNew: ModelCabinetWallT,
) => {
  const isVisibleWall = checkWallVisibilityFromCamera(wallName);
  if (!isVisibleWall)
   setLockTranslationCabinetWall(nullNameCabinetWallNew, true)
};


/**
 * Рекурсивна функція.
 * Позиціонує нову модель поруч з вже доданими моделями,
 * відносно того чи є поруч з вже доданими моделями пусті проміжки, в які поміститься нова модель.
 * Та повертає статус, вдалося правильно зпозиціонувати модель на стіні чи ні
 *
 * @param {ModelCabinetWallT} nullNameCabinetWallNew Null Name нової моделі.
 * @param {ICoordinates} sizeCabinetWallNew Розміри нової моделі.
 * @param {ModelsName_NodesT[]} oldListNullNamesCabinetsWall Масив Cabinets Wall, які були додані раніше (вже зпозиціоновані на стінах).
 * @return {boolean} true - модель правильно зпозиціонована на стіні; false - модель не вcтановлена в правильну позицію на стінах
 */
const setPositionNewModelRecursive = (
  nullNameCabinetWallNew: ModelCabinetWallT,
  sizeCabinetWallNew: ICoordinates,
  oldListNullNamesCabinetsWall: ModelsName_NodesT[]
): any => {

  // Копіюємо масив вже зпозиціонованих моделей на стінах
  const arrOldCabinetsWallCopy = oldListNullNamesCabinetsWall.slice(0);

  // Якщо масив пустий то зупиняємо рекурсію, та повертаємо false,
  // що означає що модель не втановлена в правильну позицію
  if (arrOldCabinetsWallCopy.length === 0) return false;

  // Беремо останюю модель з масиву вже зпозиціонованих моделей на стінах
  const nullNameCabinetWallOld =
    arrOldCabinetsWallCopy[arrOldCabinetsWallCopy.length - 1];

  // 1. Визначаємо стіну, на якій знаходиться модель
  const wallName = getWallNameForCabinet(nullNameCabinetWallOld);

  // Якщо стіна не знайдена то зупиняємо рекурсію, та повертаємо false,
  if (wallName === undefined) return false;

  // 2. Отримуємо масив інтервалів для стіни, на якій знаходиться модель
  const currentPlaneName = getPlaneNameFromWallName(wallName);
  const intervalsForCkeckNewPosition = getIntervalsForCkeckNewPositionCabinetWall(currentPlaneName, nullNameCabinetWallNew, sizeCabinetWallNew)

  // 3. Перевіряємо чи є поруч з обраною моделлю місце для нового Cabinet Wall (отримуємо обьект сусідніх інтервалів для моделі)
  // 3.1. Отримуємо об'єкт, який описує сусідні інтервали для моделі
  const neaborIntervalsForCabinetWall = getNeaborIntervals(
    intervalsForCkeckNewPosition,
    nullNameCabinetWallOld
  );

  // 3.2 Перевіряємо сусідні інтервали для моделі
  // чи є пусті інтервали поруч з моделлю, та чи підходять пусті інтервали по розміру
  // в результалі отримуємо позицію на стіні для встановлення моделі або undefined
  const positionOnWallForNewCabinetWall = getPositionOnWallForNewCabinetWall(
    neaborIntervalsForCabinetWall,
    sizeCabinetWallNew
  );

  // Якщо знайдена позиція для нової моделі поруч з вже доданою моделлю nullNameCabinetWallOld
  // то встановлюємо модель в цю позицію
  if (positionOnWallForNewCabinetWall !== undefined) {
    const coordsNewCabinetWallOnWall = getCoordsNewCabinetWallOnWall(
      wallName,
      positionOnWallForNewCabinetWall,
      sizeCabinetWallNew,
      nullNameCabinetWallNew
    );
    const rotationNewCabinetWallOnWall =
      getRotationNewCabinetWallOnWall(wallName);

    setPositionNewCabinetWall(
      nullNameCabinetWallNew,
      coordsNewCabinetWallOnWall,
      rotationNewCabinetWallOnWall
    );
    checkMovedNewCabinetWall(wallName, nullNameCabinetWallNew);

    return true;
  }

  // Якщо поруч з останньою доданою моделлю не знайдена позиція для нової моделі,
  // То видаляємо цю модель з масиву.
  // І йдемо на нову рекурсію, де буде перевірена передостання додана модель
  // і т.д., поки не перевіримо всі додані моделі
  arrOldCabinetsWallCopy.pop();
  return setPositionNewModelRecursive(
    nullNameCabinetWallNew,
    sizeCabinetWallNew,
    arrOldCabinetsWallCopy
  );
};

/**
 * Позиціонує Cabinet Wall на стіну в перший пустий проміжок, який шукається зправа наліво по стіні та має потрібний розмір
 *
 * @param {ModelCabinetWallT} nullNameCabinetWallNew Null Name нової моделі, яку потрібно поставити на стіні.
 * @param {WallItemT} targetWall Name стіни, на яку потрыбно додати нову модель.
 */
const setPositionCabinetOnWallRightToLeft = (
  nullNameCabinetWallNew: ModelCabinetWallT,
  targetWall: WallItemT
) => {
  const currentPlaneName = getPlaneNameFromWallName(targetWall);;
  const currentPlaneWidth = getBoxWidthThreekit({ name: currentPlaneName });
  const currentPlaneCabinetsRotation = getRotationThreekit({
    from: getSceneInstanceId(),
    name: currentPlaneName,
  });
  const { leftDirectionPlane, rightDirectionPlane} = getLeftRigthDirectionsPlane(currentPlaneName);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(currentPlaneName);

  const newModelSize = getSizeModelBoxFromAssetCabinetWall(nullNameCabinetWallNew);
  const newModelWidth = newModelSize["x"];
  const newModelHeight = newModelSize["y"];

  let offsetDistance = newModelWidth/2;

  const intervalsForCkeckNewPosition = getIntervalsForCkeckNewPositionCabinetWall(currentPlaneName, nullNameCabinetWallNew, newModelSize);

  

  const intervalForModel = getIntervalForModel(intervalsForCkeckNewPosition, newModelWidth);
  if (intervalForModel !== undefined) {
    offsetDistance = (currentPlaneWidth - intervalForModel["range"][1]) + newModelWidth/2;
  }

  const movedCoordsModel = moveCoordsByVector(
    { ...currentPlaneCoordsRight, y: getDefaultPositionYCabinetsWall(nullNameCabinetWallNew) },
    leftDirectionPlane,
    offsetDistance
  );

  setPositionNewCabinetWall(
    nullNameCabinetWallNew,
    movedCoordsModel,
    currentPlaneCabinetsRotation
  );
  checkMovedNewCabinetWall(targetWall, nullNameCabinetWallNew);

};

/**
 * Позиціонує нові Cabinets Wall поруч з вже доданими моделями,
 * відносно того чи є поруч з вже доданими моделями пусті проміжки, в які поміститься нова модель.
 *
 * @param {ActiveAndNewValuesThreekitT} objAssetIdValuesCabinetsWall Об'єкт з старими та новими моделями Cabinets Base,
 * @param {WallItemT} targetWall Стіна, до якої потрібно першочергово додавати шкафи..
 */
export const setPositionNewCabinetsWallOnWall = (
  objAssetIdValuesCabinetsWall: ActiveAndNewValuesThreekitT,
  targetWall: WallItemT
) => {
  const { activeValues, newValues } = objAssetIdValuesCabinetsWall;

  // Null Names для всіх моделей (старих та щойно доданих)
  const completedNullNamesCabinetsWall = getСompletedModelsNullNames(
    NODES_THREEKIT.MODEL_CABINET_WALL
  );

  // Формуємо масив Cabinets Wall, які були додані раніше
  const oldListNullNamesCabinetsWall = completedNullNamesCabinetsWall.slice(
    0,
    activeValues.length
  );
  // Формуємо масив нових Cabinets Wall, які потрібно розташувати в кімнаті
  const newListNullNamesCabinetsWall = completedNullNamesCabinetsWall.slice(
    activeValues.length
  );

  // Перебираємо масив нових моделей, та позиціонуємо їх на стінах
  newListNullNamesCabinetsWall.forEach((nullNameCabinetWallNew) => {
    // Первіряємо що nullNameCabinetWallNew має тип ModelCabinetWallT
    if (!isNullNameModelCabinetWallT(nullNameCabinetWallNew)) return;

    const sizeCabinetWallNew = getSizeModelBoxFromAssetCabinetWall(
      nullNameCabinetWallNew
    );

    // Якщо настінних шкафів ще немає, додаємо новий шкаф на стіну targetWall
    if (oldListNullNamesCabinetsWall.length < 1) {
      setPositionCabinetOnWallRightToLeft(nullNameCabinetWallNew, targetWall);
      oldListNullNamesCabinetsWall.push(nullNameCabinetWallNew);
      return;
    }

    // Якщо вже є додані Cabinets Wall, то шукаємо вільне місце поряд з доданими шкафами
    // Позиціонуємо нову модель відносно попередньо доданих моделей
    const isPositioningNewModel = setPositionNewModelRecursive(
      nullNameCabinetWallNew,
      sizeCabinetWallNew,
      oldListNullNamesCabinetsWall
    );

    // Якщо isPositioningNewModel = true, це означає що модель правильно зпозиціонована на стіні
    // Якщо isPositioningNewModel = false, це означає що модель не вcтановлена в правильну позицію на стінах
    // Отже, якщо модель не не вcтановлена в правильну позицію,
    // то шукаємо чи є вільна стіна для додавання нових Cabinet Base
    if (!isPositioningNewModel) {
      const allWallsLength = Object.keys(getAllWallsNode()).length;
      const wallName = getTargetWallNameForAddedOneCabinetWall(
        targetWall,
        getNumberNodeThreekitFromName(targetWall),
        allWallsLength,
        sizeCabinetWallNew,
        nullNameCabinetWallNew
      );
      if (wallName !== undefined) {
        setPositionCabinetOnWallRightToLeft(nullNameCabinetWallNew, wallName);
      } else {
        // Цього випадку не може бути, так як наявність місця для нових моделей попередньо перевіряється в міделварі
        // за допомогою функції getWallNameForAddedCabinetsBase
      }
    }

    // Додаємо щойно додану модель до списку oldListNullNamesCabinetsBase
    // Для того, що пошук позиції для наступної моделі починався з щойно доданої
    oldListNullNamesCabinetsWall.push(nullNameCabinetWallNew);
  });
};

/**
 * СТАРА ФУНКЦІЯ ДЛЯ ДОДАВАННЯ МОДЕЛЕЙ НА СТІНИ В ПОРЯДКУ СПРАВА НАЛІВО
 * 
 * При додаванні на стіну моделей, розміщує моделі таким чином, щоб вовни не конфліктувати (не перетинались) одна з одною.
 *
 * @param {ActiveAndNewValuesThreekitT} objAssetIdValuesCabinetsWall Об'єкт з массивом вже встановлених моделей в атрибут типу массив - objAssetIdValuesCabinetsWall["activeValues"],
 * та массив нових значень(які потрібно додати на сцену) - objAssetIdValuesCabinetsWall["activeValues"].
 */
export const setPositionCabinetOnWall = (
  objAssetIdValuesCabinetsWall: ActiveAndNewValuesThreekitT,
  targetWall: WallItemT
) => {
  const { activeValues, newValues } = objAssetIdValuesCabinetsWall;
  const currentPlaneName = getPlaneNameFromWallName(targetWall);;
  // const currentPlaneName = getPlaneNameInFrontCamera();
  const currentPlaneWidth = getBoxWidthThreekit({ name: currentPlaneName });
  const {planeLeftName, planeRightName} = getNeighborPlanes(currentPlaneName);
  // console.log('planeLeftName --- ==== ',planeLeftName);
  // console.log('planeRightName --- ==== ',planeRightName);
  const currentPlaneCabinetsRotation = getRotationThreekit({
    from: getSceneInstanceId(),
    name: currentPlaneName,
  });
  const { leftDirectionPlane, rightDirectionPlane} = getLeftRigthDirectionsPlane(currentPlaneName);
  const [currentPlaneCoordsLeft, currentPlaneCoordsRight] =
    getStartEndCoordsPlane(currentPlaneName);

  let intervalsInfoOnWall: ArrWallRangesT = getIntervalsInfoOnWall(currentPlaneName);;

  for (let i = activeValues.length; i < activeValues.length + newValues.length; i++) {
    const nextPointName: ModelCabinetWallT = `${NODES_THREEKIT.MODEL_CABINET_WALL}${i}`;
    // const newModelSize = getSizeModelRelativeTransform(nextPointName);
    const newModelSize = getSizeModelBoxFromAssetCabinetWall(nextPointName);
    // console.log('newModelSize --- ==== ',newModelSize);
    const newModelWidth = newModelSize["x"];
    const newModelDepth = newModelSize["z"];
    const newModelHeight = newModelSize["y"];

    let offsetDistance = newModelWidth/2;
    
    // в cornerOffset ще потрібно додати ширину прижимної планки
    const cornerOffset = newModelDepth;
    // якщо позиція нової моделі знаходиться близько до сусідньої стіни зліва
    // то перевіряємо чи не стоїть на сусідній зліва стіні модель в спільному куті
    if (!!planeLeftName) { // && offsetDistance <= cornerOffset
      const intervalsInfoOnWallLeft = getIntervalsInfoOnWall(planeLeftName);
      const isCornerIntervalEmpty = checkCornerIntervalEmpty(intervalsInfoOnWallLeft, cornerOffset, "right");
      // якщо кут на сусідній стіні зайнятий,
      // то в масив проміжків додаємо новий проміжок розміром ModelDepth + ширина добору
      if (!isCornerIntervalEmpty) {
        const newInterval: WallRangeT = {
          empty: false,
          range: [0, cornerOffset]
        }
        intervalsInfoOnWall = addIntervalToArrIntervals(intervalsInfoOnWall, newInterval);
      }
    }

    // якщо позиція нової моделі знаходиться близько до сусідньої стіни справа
    // то перевіряємо чи не стоїть на сусідній стіні справа модель в спільному куті
    if (!!planeRightName) { // && offsetDistance >= currentPlaneWidth - cornerOffset
      const intervalsInfoOnWallRight = getIntervalsInfoOnWall(planeRightName);
      const isCornerIntervalEmpty = checkCornerIntervalEmpty(intervalsInfoOnWallRight, cornerOffset, "left");
      // якщо кут на сусідній стіні зайнятий,
      // то в масив проміжків додаємо новий проміжок розміром ModelDepth + ширина добору
      if (!isCornerIntervalEmpty) {
        const newInterval: WallRangeT = {
          empty: false,
          range: [currentPlaneWidth - cornerOffset, currentPlaneWidth]
        }
        intervalsInfoOnWall = addIntervalToArrIntervals(intervalsInfoOnWall, newInterval);
      }
    }

    const intervalForModel = getIntervalForModel(intervalsInfoOnWall, newModelWidth);
    if (intervalForModel !== undefined) {
      // offsetDistance = intervalForModel["range"][0] + newModelWidth/2;
      offsetDistance = (currentPlaneWidth - intervalForModel["range"][1]) + newModelWidth/2;
    }

    // const movedCoordsModel = moveCoordsByVector(
    //   // { ...currentPlaneCoordsLeft, y: currentPlaneCoordsLeft["y"] - newModelHeight},
    //   { ...currentPlaneCoordsLeft, y: (CABINETS_WALL_BOTTOM_POSITION + CABINETS_WALL_STANDART_HEIGHT) - newModelHeight},
    //   rightDirectionPlane,
    //   offsetDistance
    // );
    const movedCoordsModel = moveCoordsByVector(
      { ...currentPlaneCoordsRight, y: (CABINETS_WALL_BOTTOM_POSITION + CABINETS_WALL_STANDART_HEIGHT) - newModelHeight },
      leftDirectionPlane,
      offsetDistance
    );

    setTranslationThreekit({
      from: getSceneInstanceId(),
      name: nextPointName,
      value: movedCoordsModel,
    });
    setRotationThreekit({
      from: getSceneInstanceId(),
      name: nextPointName,
      value: {
        x: 0,
        y: currentPlaneCabinetsRotation["y"],
        z: 0,
      },
    });

    // оновлюємо позицію коннектора для вертикального з'єднання з напольними шкафами
    updatePositionConnectorBottom(getNodeIdFromName(nextPointName));
    // emulationMoveCabinetWall(nextPointName)

    // створюємо проміжок для щойно доданої моделі і додаємо його в масив проміжків intervalsInfoOnWall
    // для того щом наступна модель з массиву newValues враховувала проміжок попередньої моделі
    const intervalForNewModel: WallRangeT = {
      empty: false,
      range: [offsetDistance - newModelWidth/2, offsetDistance + newModelWidth/2],
      name: nextPointName,
    }
    intervalsInfoOnWall = addIntervalToArrIntervals(intervalsInfoOnWall, intervalForNewModel);

  }

};