import { ModelsName_NodesT, WallItemT } from "../../utils/constants/nodesNamesThreekit";
import { getTranslationThreekit } from "../../utils/threekit/general/getFunctions";
import { getArrWallsNames } from "../wallsAndFloor/getGeneralWallInfo";
import { getSizeModelRelativeTransform, isPointOnLine } from "../intervals/getIntervalsOnWallForCabinetsWall";
import { getStartEndPointWall } from "../wallsAndFloor/getStartEndPointWall";

/**
 * Шукає стіну (Name Wall). на якій розташовується шкаф.
 *
 * @param {ModelsName_NodesT} nullNameCabinet Null Name для моделі.
 * @return {WallRangeT} Інтервал для щойно доданої моделі (для додавання в загальний масив інтервалів на стіні). ?????????????????????????????? // todo - чи потрібно це
 */
export const getWallNameForCabinet = (
  nullNameCabinet: ModelsName_NodesT
): WallItemT | undefined => {
  const posModel = getTranslationThreekit({ name: nullNameCabinet });
  const allWallsNames = getArrWallsNames();
  let resultWallName: WallItemT | undefined;
  allWallsNames.forEach((wallName) => {
    const wallSize = getSizeModelRelativeTransform(wallName);
    const [wallCoordsLeft, wallCoordsRight] = getStartEndPointWall(wallName);
    const isModelNullOnWall = isPointOnLine(
      { ...wallCoordsLeft, y: 0 },
      { ...wallCoordsRight, y: 0 },
      { ...posModel, y: 0 },
      wallSize["z"] / 2
    );
    if (isModelNullOnWall) resultWallName = wallName;
  })
  return resultWallName;
}