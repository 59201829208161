import { ICoordinates, ISceneResult } from "@threekit-tools/treble/dist/types";
import { ModelsName_NodesT } from "../../../utils/constants/nodesNamesThreekit";
import { getTHREE } from "../../../utils/three/general/getFunctionsTHREE";
import { getEvalNode, getInstanceIdAssetFromNullModel } from "../../../utils/threekit/general/getFunctions";
import { getSizeModelRelativeTransform } from "../../intervals/getIntervalsOnWallForCabinetsWall";
import { checkModelBoxScale } from "../cabinetsWall/size";

const getNodeBoxForAppliances = (modelName: ModelsName_NodesT): ISceneResult => {
  const instanceId = getInstanceIdAssetFromNullModel({
    name: modelName,
  });
  
  const modelBoxEvalNodeDishwasher = getEvalNode({
    from: instanceId,
    name: "Dishwasher_mdl",
  });

  if (modelBoxEvalNodeDishwasher !== undefined)
    return modelBoxEvalNodeDishwasher;

  const modelBoxEvalNodeOven = getEvalNode({
    from: instanceId,
    name: "oven_*",
  });

  if (modelBoxEvalNodeOven !== undefined)
    return modelBoxEvalNodeOven;

  const modelBoxEvalNodeRefrigerator = getEvalNode({
    from: instanceId,
    name: "Refrigerator_*",
  });

  if (modelBoxEvalNodeRefrigerator !== undefined)
    return modelBoxEvalNodeRefrigerator;

  const modelBoxEvalNodeAppliance = getEvalNode({
    from: instanceId,
    name: "Appliance_*",
  });

  return modelBoxEvalNodeAppliance;
}

/**
 * Функція для визначення розмірів моделі по її каркасу.
 * без урахування молдингів та інших навісних елементів тумби.
 *
 * @param {ModelCabinetWallT} modelName Null name моделі, для якої шукаємо розміри.
 * @returns {ICoordinates} Pозмір об'єкта.
 */
export const getSizeModelBoxFromAssetAppliances = (modelName: ModelsName_NodesT): ICoordinates => {
  const THREE = getTHREE();
  const modelBoxEvalNode = getNodeBoxForAppliances(modelName);
  try {
    //@ts-ignore
    const boundingBox = modelBoxEvalNode.getBoundingBox({
      //@ts-ignore
      relativeTransform: modelBoxEvalNode.worldTransform
    });
    const sizeModelBoxFromScale = boundingBox.getSize(new THREE.Vector3());
    const sizeModelBoxOriginal = checkModelBoxScale(sizeModelBoxFromScale, modelBoxEvalNode);
    return sizeModelBoxOriginal;
  } catch (error) {
    console.error(error);
    return getSizeModelRelativeTransform(modelName);
  }
}