import React from "react";
import s from "./SettingsProduts.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/useStoreHooks";
import { getLabelPositionCabinet, getValueOption } from "../../store/selectors/settingsUISelectors";
import { BtnBackMenu } from "../StandartComponents/Buttons/BtnBackMenu";
import { updateSettings } from "../../store/slices/settingsUI/settingsUISlice";

export const SettingsProduts = ({ ...props }) => {
  const { children } = props;
  const dispatch = useAppDispatch();
  const activeCabinetsCategory = useAppSelector(getValueOption({idOption: "CategoryCabinet"}));
  // const activePositionCabinet = useAppSelector(getValueOption({idOption: "PositionCabinet"}));
  const labelPositionCabinet = useAppSelector(getLabelPositionCabinet({idOption: "PositionCabinet"}));

  const handleClickBack = () => {
    dispatch(updateSettings([{optionId: "CategoryCabinet", value: ""}]))
  }

  if (!!!activeCabinetsCategory) return <></>

  return (
    <>
      <div className={s.desktopSectionCabinets}>
        <div className={s.scrollBlock}>
          <div className={s.titleBlock}>
            <h3 className={s.settingsTitle}>{`${String(activeCabinetsCategory).split(' / ')[0]} ${labelPositionCabinet}`}</h3>
          </div>
          <BtnBackMenu text="Back" onClick={handleClickBack} />
          { children }
        </div>
      </div>
    </>
  )
};
