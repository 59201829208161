export const enum ATTRIBUTES_NAMES_THREEKIT {
  WALLS = "Walls",
  WINDOWS = "Windows",
  DOORS = "Doors",
  OPENINGS = "Openings",

  HEIGHT = "Height",
  CABINETS_BASE = "Cabinets Base",
  CABINETS_WALL = "Cabinets Wall",
  CABINETS_ISLAND = "Cabinets Island",
  APPLIANCES = "Appliances",
  CABINET_MATERIAL = "Cabinet material",
  DOOR_STYLE = "Door Style",
  WALL_THICKNESS = "Wall Thickness",

  DIMENSIONS = "showDimensions",
  DIMENSIONS_EMPTY_LEFT = "dimensionEmptyLeft",
  DIMENSIONS_EMPTY_RIGHT = "dimensionEmptyRight",

  // Support Attributes
  PHYSICS_CABINETS_BASE = "OnOffPhysicsCabinetsBase",
  PHYSICS_CABINETS_WALL = "OnOffPhysicsCabinetsWall",

  SYSTEM_UPDATE_FEATURES = "update_features_item[system:variable]",

  IS_MODEL_CORNER_IN_CORNER = "isCornerCabinetInCorner",
  ROTATE_CABINET_CORNER = "rotateCabinetCorner",
}

export const WINDOWS_MAX_LENGTH = 5;
export const DOORS_MAX_LENGTH = 5;
export const OPENINGS_MAX_LENGTH = 5;