import { Vector3 } from "three";
import { FEATURES_INNER_NODES_THREEKIT, FeaturesName_NodesT } from "../../utils/constants/nodesNamesThreekit";
import { getEvalNode, getInstanceIdAssetFromNullModel, getTranslationThreekit } from "../../utils/threekit/general/getFunctions";
import * as THREE from "three";
import { getSizeModelRelativeTransform } from "../intervals/getIntervalsOnWallForCabinetsWall";
import { getVector3FromCoordinates } from "../../utils/three/general/getFunctionsTHREE";

export const getFeaturesSize = (nullNameFeature: FeaturesName_NodesT): Vector3 => {
  const instanceIdAssetFeature = getInstanceIdAssetFromNullModel({name: nullNameFeature});
  const evalNode = getEvalNode({
    from: instanceIdAssetFeature,
    name: FEATURES_INNER_NODES_THREEKIT.CONTAINER_POS_NULL
  });
  try {
    //@ts-ignore
    const boundingBoxRelativeTransform = evalNode.getBoundingBox({
      //@ts-ignore
      relativeTransform: evalNode.worldTransform,
    });
    const size = boundingBoxRelativeTransform.getSize(new THREE.Vector3());
    return size;
  } catch (error) {
    console.error("getFeaturesSize error from instanceIdAssetFeature", error);
    return getVector3FromCoordinates(getSizeModelRelativeTransform(nullNameFeature));
  }

}